import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Delaunay } from 'd3-delaunay';
import * as d3Scale from 'd3-scale';
import * as d3 from 'd3-selection';
import * as d3Zoom from 'd3-zoom';
import { layoutGreedy } from '@d3fc/d3fc-label-layout';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Dataset } from 'src/app/datasets/models';
import { Point, MapProperties, MapViewboxBounds } from 'src/app/map/models';
import { MapService } from 'src/app/map/services';
import { MapStore } from 'src/app/map/store';
import { DatasetsStore } from 'src/app/datasets/store';
import { Label, layoutLabel, layoutRemoveOverlaps, textLabel } from './labels-layout';


@Component({
  selector: 'app-map-labels',
  templateUrl: './map-labels.component.html',
  styleUrls: ['./map-labels.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapLabelsComponent implements OnInit, OnDestroy {

  @Input() props: MapProperties;
  @Output() fitZoom = new EventEmitter<number[][]>();
  private coords_range: number[] = null;
  private svgContainer: any;
  private labelsContainer: any;
  private voronoiContainer: any;
  private textLabel: any;
  private labeler: Label;
  private x: d3Scale.ScaleLinear<number, number>;
  private y: d3Scale.ScaleLinear<number, number>;
  private currentX: d3Scale.ScaleLinear<number, number>;
  private currentY: d3Scale.ScaleLinear<number, number>;
  private zoomTransform: d3Zoom.ZoomTransform;
  private labelStrategy = layoutRemoveOverlaps(layoutGreedy()); // layoutGreedy, layoutAnnealing et / ou layoutRemoveOverlaps
  clickInput$ = new Subject<MapViewboxBounds>();
  viewBoxInput$ = new Subject<MapViewboxBounds>();
  private subscriptions: Subscription;

  constructor(private mapService: MapService, private mapStore: MapStore, private dsStore: DatasetsStore) {
  }

  ngOnInit() {
    this.subscriptions = this.mapStore.viewBoxPoints$.subscribe(_ => {
      this.layoutLabels();
    });
    this.subscriptions.add(this.mapStore.selectedPoint$.subscribe(_ => {
      this.drawLabels();
    }));
    this.subscriptions.add(this.mapStore.searchPoints$.subscribe(points => {
      this.layoutLabels();
      this.fitZoomToSearch(points);
    }));
    this.subscriptions.add(this.dsStore.dataset$.subscribe((dataset: Dataset) => {
      this.coords_range = (dataset && dataset.metadata && ('coords_range' in dataset.metadata)) ? dataset.metadata['coords_range'] : null;
      this.setupForDataset();
    }));
    this.subscriptions.add(this.clickInput$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(bounds => this.mapService.searchInBounds(bounds)));
    this.subscriptions.add(this.viewBoxInput$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(bounds => this.mapService.updateViewBox(bounds, true)));
    this.subscriptions.add(this.mapStore.groups$.subscribe(_ => {
      this.viewBoxInput$.next(this.getMapFilters());
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initSvg() {
    this.svgContainer = d3.select('svg.labels-svg')
      .attr('preserveAspectRatio', 'none');
    this.svgContainer.on('click', this.getFiltersFromMouseClick.bind(this));
    this.labelsContainer = this.svgContainer.select('g.labels-g');
    this.voronoiContainer = this.svgContainer.select('g.voronoi-g');
    this.setupForDataset();
  }

  setupForDataset() {
    if (this.labelsContainer && this.coords_range) {
      this.textLabel = textLabel(this.dsStore.natures, this.onLabelClick.bind(this));
      this.labelsContainer.selectAll('.label').remove();
      this.voronoiContainer.selectAll('g.theme').remove();
      const xScaleF = this.props.width / 512;
      const yScaleF = this.props.height / 512;
      this.x = d3Scale.scaleLinear().domain([this.coords_range[0] * xScaleF, this.coords_range[1] * xScaleF]).range([0, this.props.width]);
      this.y = d3Scale.scaleLinear().domain([this.coords_range[0] * yScaleF, this.coords_range[1] * yScaleF]).range([this.props.height, 0]);
      if (this.zoomTransform) {
        this.currentX = this.zoomTransform.rescaleX(this.x);
        this.currentY = this.zoomTransform.rescaleY(this.y);
      }
      this.labeler = layoutLabel(this.labelStrategy)
        .size((_: Point, i: number, g: Element) => {
          // measure the label and add the required padding
          const img = g[i].getElementsByTagName('image')[0];
          const txt = g[i].getElementsByTagName('text')[0];
          const size = img ? img.getBBox() : txt.getBBox();
          // const textSize = g[i].getElementsByTagName('text')[0].getBBox();
          return [size.width + 2 * 8, size.height + 2 * 6];
        }).position((d: Point) => {
          return [this.currentX(d.position[0]), this.currentY(d.position[1])];
        }).component(this.textLabel);
    }
  }

  zoomed(t: d3Zoom.ZoomTransform) {
    this.zoomTransform = t;
    this.currentX = t.rescaleX(this.x);
    this.currentY = t.rescaleY(this.y);

    // update the viewBox value
    this.viewBoxInput$.next(this.getMapFilters());
    this.drawLabels();
  }

  /**
  * Run a label layout strategy, placing the labels on the map and hidding labels
  * that overlap.
  */
  layoutLabels() {
    if (this.labelsContainer) {
      // create the layout that positions the labels
      const points = this.filterPointsWithBounds(this.mapStore.labelPoints.toArray());
      if (points.length > 0) {
        this.labelsContainer.datum(points, (p: Point) => p.id).call(this.labeler);
        this.drawClusters();
      }
    }
  }

  /**
  * Draw the cluster borders.
  */
  drawClusters() {
    if (this.mapStore.clusterPoints.length < 3) {
      this.voronoiContainer.selectAll('g.theme').remove();
      return;
    }
    const selection = this.voronoiContainer.selectAll('g.theme');
    if (this.mapStore.clusterPoints.length === selection.size()) {
      return;
    }
    const positions = this.mapStore.clusterPoints.map(p => [this.x(p.position[0]), this.y(p.position[1])]);
    const delaunay = Delaunay.from(positions);
    const boundX = this.currentX.range();
    const boundY = this.currentY.range();
    const voronoi = delaunay.voronoi([boundX[0], boundY[1], boundX[1], boundY[0]]);

    const themes = selection.data(this.mapStore.clusterPoints, (p: Point) => p.id);
    themes.exit().remove();
    themes.enter().append('g').classed('theme', true)
      .merge(themes).attr('id', (data: Point) => 'theme' + data.id).each((data: Point, i: number, group: Element) => {
        const node = d3.select(group[i]);
        const paths = node.selectAll<SVGPathElement, Point>('path').data([data]);
        paths.exit().remove();
        paths.enter().append('path').classed('cluster-path', true)
          .style('fill', 'none')
          .style('fill-opacity', 0.5)
          .style('stroke-width', '1px')
          // .style('stroke', '#c8c8c880')
          .attr('vector-effect', 'non-scaling-stroke')
          .merge(paths)
          .attr('d', voronoi.renderCell(i));
      });
  }

  /**
  * Extract the bounds of the search points array (min/max x&y coordinates)
  * and emit a fitZoom event.
  */
  private fitZoomToSearch(searchPoints: Point[]) {
    if (this.labelsContainer && this.x && this.y && searchPoints && searchPoints.length > 1) {
      const bounds = [[0, 0], [0, 0]];
      searchPoints.forEach((p, idx) => {
        if (idx === 0) {
          bounds[0][0] = p.position[0];
          bounds[0][1] = p.position[1];
          bounds[1][0] = p.position[0];
          bounds[1][1] = p.position[1];
        } else {
          if (p.position[0] < bounds[0][0]) { bounds[0][0] = p.position[0]; }
          if (p.position[1] < bounds[0][1]) { bounds[0][1] = p.position[1]; }
          if (p.position[0] > bounds[1][0]) { bounds[1][0] = p.position[0]; }
          if (p.position[1] > bounds[1][1]) { bounds[1][1] = p.position[1]; }
        }
      });
      const searchBounds = [[this.x(bounds[0][0]), this.y(bounds[1][1])], [this.x(bounds[1][0]), this.y(bounds[0][1])]];
      this.fitZoom.emit(searchBounds);
    }
  }

  /**
  * Update the position/style of labels without running a layout strategy again.
  */
  drawLabels() {
    if (!this.labelsContainer) {
      return;
    }
    this.labelsContainer.selectAll('.label').attr('transform', (data: Point, index: number, group: Element) => {
      const node = group[index];
      const anchorX = parseFloat(node.getAttribute('anchor-x')) || 0.0;
      const anchorY = parseFloat(node.getAttribute('anchor-y')) || 0.0;
      return 'translate(' + (this.currentX(data.position[0]) - anchorX) + ',' + (this.currentY(data.position[1]) - anchorY) + ')';
    }).classed('selected', (point: Point) => point.is_selected)
      .classed('search-point', (point: Point) => point.is_search_result && !point.is_selected);
    this.voronoiContainer.attr('transform', this.zoomTransform);
  }

  /**
  * Callback called when the user clicks on a label on the map
  */
  onLabelClick(point: Point, _index: number, _group: Element) {
    if (point && point.id) {
      d3.event.stopPropagation();
      this.mapStore.setSelectedPoint(point);
    }
  }

  getProjection(x: number, y: number): number[] {
    return [this.x(x), this.y(y)];
  }

  getCenterCoordinates(): number[] {
    return [this.currentX.invert(this.props.width / 2), this.currentY.invert(this.props.height / 2)];
  }

  getFiltersFromMouseClick() {
    if (this.currentX && this.currentY) {
      const mouseX = d3.event.layerX || d3.event.offsetX;
      const mouseY = d3.event.layerY || d3.event.offsety;
      const d = 25;
      this.blinkClickRectangle(mouseX, mouseY, d);
      const xrange = [this.currentX.invert(mouseX - d), this.currentX.invert(mouseX + d)],
        yrange = [this.currentY.invert(mouseY - d), this.currentY.invert(mouseY + d)];
      this.clickInput$.next(new MapViewboxBounds(xrange, yrange));
    }
  }

  private blinkClickRectangle(mouseX: number, mouseY: number, d: number) {
    const x = mouseX - d;
    const y = mouseY - d;
    const x2 = mouseX + d;
    const y2 = mouseY + d;
    let rect = this.svgContainer.select('.click-rect');
    if (rect.empty()) {
      rect = this.labelsContainer.append('rect').classed('click-rect', true);
    }
    rect.attr('x', x)
      .attr('y', y)
      .attr('width', Math.abs(x2 - x))
      .attr('height', Math.abs(y2 - y))
      .attr('stroke', 'ivory')
      .attr('rx', 5).attr('ry', 5)
      .attr('stroke-width', '1.5px')
      .attr('fill', 'none').attr('stroke-opacity', 1)
      .transition().duration(750).attr('stroke-opacity', 0);
  }

  private getMapFilters(): MapViewboxBounds {
    // clone filters and update ranges
    if (this.currentX && this.currentY) {
      const ydomain = this.currentY.domain();
      const bounds = new MapViewboxBounds(this.currentX.domain(), [ydomain[1], ydomain[0]]);
      if (this.zoomTransform) {
        bounds.zoom = this.zoomTransform.k;
      }
      return bounds;
    }
    return new MapViewboxBounds([], []);
  }

  private filterPointsWithBounds(points: Point[]): Point[] {
    if (this.currentX && this.currentY) {
      const ydomain = this.currentY.domain();
      const xdomain = this.currentX.domain();
      return points.filter(point => xdomain[0] < point.position[0]
        && point.position[0] < xdomain[1]
        && ydomain[0] < point.position[1]
        && point.position[1] < ydomain[1]);
    }
    return points;
  }

  setDimedMode(mode: boolean) {
    this.svgContainer.classed('dimed', mode);
  }
}
