import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable} from 'rxjs';
import { Nature, GroupFilter } from 'src/app/datasets/models';
import { Point } from 'src/app/map/models';
import { MapService } from 'src/app/map/services';
import { DatasetsStore } from 'src/app/datasets/store';
import { GroupFiltersDialogComponent } from '../group-control/group-filters/group-filters.component';

@Component({
  selector: 'app-point-card',
  templateUrl: './point-card.component.html',
  styleUrls: ['./point-card.component.scss']
})
export class PointCardComponent implements OnInit, OnChanges {

  @Input() point: Point;
  @Input() active: boolean;
  @Input() open: boolean;
  @Output() action = new EventEmitter<string>();
  nature: Nature;
  labels$: Observable<Array<Nature>>;
  pointNature = '';
  url = '';
  groupFilter: GroupFilter;

  constructor(public mapService: MapService, private dsStore: DatasetsStore, public dialog: MatDialog) {
    this.labels$ = this.dsStore.labels$;
  }

  ngOnInit() {
    this.onPointChange();
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if ('point' in changes) {
      this.onPointChange();
    }
  }

  private onPointChange() {
    this.nature = this.dsStore.natures.find(p => p.key === this.point.nature);
    if (!this.nature) {
      this.nature = {
        id: 88888, key: 'cluster', label: 'Cluster', color: '#fdd835', checked: true,
        layer: false, inactive: false, limit: 0, icon: 'format_shapes', tooltip: '', layer_tooltip: '', extra_fields: []
      };
    }
    this.formatUrl();
    this.formatPointNature();
    this.setGroupFilter();
  }

  clear() {
    this.action.emit('clear');
  }

  targeted() {
    this.action.emit('target');
  }

  getNeighbors(nature: string) {
    this.action.emit(nature);
  }

  filterMap() {
    if (this.groupFilter.type === 'DI') {
      this.openDialog();
    } else {
      this.mapService.displayGroupForPoint(this.groupFilter, this.point);
    }
  }

  private openDialog(): void {
    const dialogRef = this.dialog.open(GroupFiltersDialogComponent,
      { panelClass: 'group-filters-dialog', minWidth: 400, maxWidth: 500, data: { key: this.groupFilter.key, point: this.point } });

    dialogRef.afterClosed().subscribe(filters => {
      if (filters && filters.length > 0) {
        this.mapService.displayGroup(filters);
      } else if (filters) {
        this.mapService.clearGroup();
      }
    });
  }

  private setGroupFilter() {
    const dataset = this.dsStore.dataset;
    if (dataset && dataset.filters) {
      this.groupFilter = dataset.filters.find(filter => {
        return filter.natures && (filter.natures.split(',').indexOf(this.point.nature) > -1);
      });
    }
  }

  private formatUrl() {
    if (this.point.url && this.point.url.length > 0) {
      if (this.point.url.startsWith('http://') || this.point.url.startsWith('https://')) {
        this.url = this.point.url;
      } else if ('base_url' in this.dsStore.dataset.metadata) {
        this.url = this.dsStore.dataset.metadata['base_url'] + '/' + this.point.url;
      }
    } else {
      this.url = '';
    }
  }

  private formatPointNature() {
    let nature = this.point.nature || '';
    if (nature.indexOf('_clusters') > 0) {
      nature = 'Clusters';
    }
    if (nature.endsWith('s')) {
      nature = nature.slice(0, -1);
    }
    if (nature.length > 1) {
      nature = nature.charAt(0).toUpperCase() + nature.slice(1);
    }
    this.pointNature = nature;
  }

}
