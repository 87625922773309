import { of, Subject, from } from 'rxjs';
import { debounceTime, tap, distinctUntilChanged, switchMap, mergeMap } from 'rxjs/operators';
import { PointService } from './point.service';
import { MapStore } from 'src/app/map/store';
import { DatasetsStore } from 'src/app/datasets/store';
import * as i0 from "@angular/core";
import * as i1 from "./point.service";
import * as i2 from "../store/map.store";
import * as i3 from "../../datasets/store/datasets.store";
var MapService = /** @class */ (function () {
    function MapService(pointService, mapStore, dsStore) {
        var _this = this;
        this.pointService = pointService;
        this.mapStore = mapStore;
        this.dsStore = dsStore;
        this._clusterCache = {};
        this.typeaheadInput$ = new Subject();
        this.typeaheadResults = [];
        this.searchLoading = false;
        this.typeaheadPoints$ = this.typeaheadInput$.pipe(debounceTime(300), distinctUntilChanged(), tap(function (val) {
            _this.searchLoading = true;
            _this.typeaheadInput = val;
        }), switchMap(function (query) { return _this.search(query).pipe(tap(function (results) {
            _this.typeaheadResults = results;
            _this.searchLoading = false;
        })); }));
    }
    MapService.prototype.resetService = function () {
        this._clusterCache = {};
        this.typeaheadResults = [];
        this.typeaheadInput$.next('');
    };
    /**
    * Search for points within bounds.
    */
    MapService.prototype.searchInBounds = function (bounds) {
        var _this = this;
        var dataset = this.dsStore.dataset;
        var obs = of([]);
        if (dataset && dataset.id) {
            var natures = this.mapStore.searchFilters.filter(function (f) { return f.checked; }).map(function (f) { return f.key; }).join(',');
            obs = this.pointService.fetchPoints(dataset, natures, '10', bounds.xrange, bounds.yrange, null, this.mapStore.groupQueryString);
        }
        obs.subscribe(function (points) { return _this.mapStore.setSearchPoints(points, 'Highest ranked points in search zone'); });
    };
    /**
    * Get new points within viewBox.
    */
    MapService.prototype.updateViewBox = function (bounds, fetchThemes) {
        var _this = this;
        var dataset = this.dsStore.dataset;
        this.mapStore.resetViewBoxPoints();
        var obs = of([]);
        if (dataset && dataset.id) {
            if (fetchThemes) {
                this._getClusters(bounds.zoom);
            }
            var natures = this.dsStore.labels.filter(function (n) { return n.checked && n.limit > 0; });
            obs = from(natures).pipe(mergeMap(function (nature) { return _this.pointService.fetchPoints(dataset, nature.key, String(nature.limit), bounds.xrange, bounds.yrange, null, _this.mapStore.groupQueryString); }));
        }
        obs.subscribe(function (points) { return _this.mapStore.addViewBoxPoints(points); });
    };
    MapService.prototype.search = function (query) {
        var dataset = this.dsStore.dataset;
        if (dataset && dataset.id && query) {
            var natures = this.mapStore.searchFilters.filter(function (f) { return f.checked; }).map(function (f) { return f.key; }).join(',');
            return this.pointService.fetchPoints(dataset, natures, '20', null, null, query, this.mapStore.groupQueryString);
        }
        return of([]);
    };
    MapService.prototype.selectSearchResult = function (point) {
        if (point && point.id) {
            this.mapStore.setSelectedPoint(point, true);
        }
    };
    MapService.prototype.selectAllSearchResults = function () {
        if (this.typeaheadResults.length > 0) {
            var title = 'Search results for ' + this.typeaheadInput;
            this.mapStore.setSearchPoints(this.typeaheadResults, title);
        }
    };
    MapService.prototype.clearSearchResults = function () {
        this.mapStore.setSearchPoints([], '');
    };
    MapService.prototype.getNeighbors = function (point, nature) {
        var _this = this;
        var dataset = this.dsStore.dataset;
        this.mapStore.setSelectedPoint(point);
        this.pointService.getNeighbors(dataset, point, nature)
            .subscribe(function (points) { return _this.mapStore.setSearchPoints(points, "Nearest " + nature + " for " + point.label); });
    };
    /**
    * Get the clusters either from cache or from the server.
    */
    MapService.prototype._getClusters = function (zoom) {
        var _this = this;
        if (zoom > 80) {
            this.mapStore.clearClusters();
            return;
        }
        var nature = 'hl_clusters', limit = '20';
        if (zoom > 28) {
            nature = 'vll_clusters';
            limit = '512';
        }
        else if (zoom > 8) {
            nature = 'll_clusters';
            limit = '128';
        }
        else if (zoom > 2.5) {
            nature = 'ml_clusters';
            limit = '32';
        }
        if (nature in this._clusterCache) {
            this.mapStore.setClusters(this._clusterCache[nature]);
        }
        else {
            var dataset = this.dsStore.dataset;
            this.pointService.fetchPoints(dataset, nature, limit, null, null, null).subscribe(function (points) {
                points.forEach(function (p) { return p.is_cluster = true; });
                _this._clusterCache[nature] = points;
                _this.mapStore.setClusters(points);
            });
        }
    };
    MapService.prototype.clearGroup = function () {
        this.mapStore.clearGroup();
    };
    MapService.prototype.displayGroup = function (filters) {
        var _this = this;
        if (filters.length > 0) {
            var dataset = this.dsStore.dataset;
            this.pointService.getDatasetGroup(dataset, filters).subscribe(function (group) { return _this.mapStore.setGroup([group], filters); });
        }
    };
    MapService.prototype.displayGroupForPoint = function (groupFilter, point) {
        var _this = this;
        var dataset = this.dsStore.dataset;
        this.pointService.getGroupByReference(dataset, groupFilter, point.rank).subscribe(function (group) { return _this.mapStore.setGroup([group], [{ field: groupFilter.key, value: point.label, rank: point.rank, bitmap_index: undefined, location: undefined }]); });
    };
    MapService.prototype.requestGroupTile = function (group, zoom, x, y, xend, yend, layers) {
        if (layers.length === 0) {
            return of({});
        }
        return this.pointService.requestGroupTile(group, zoom, x, y, xend, yend, layers);
    };
    MapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapService_Factory() { return new MapService(i0.ɵɵinject(i1.PointService), i0.ɵɵinject(i2.MapStore), i0.ɵɵinject(i3.DatasetsStore)); }, token: MapService, providedIn: "root" });
    return MapService;
}());
export { MapService };
