/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../navbar/navbar.component.ngfactory";
import * as i3 from "../navbar/navbar.component";
import * as i4 from "../../auth/services/auth.service";
import * as i5 from "../../datasets/store/datasets.store";
import * as i6 from "@angular/router";
import * as i7 from "../footer/footer.component.ngfactory";
import * as i8 from "../footer/footer.component";
import * as i9 from "./main.component";
var styles_MainComponent = [i0.styles];
var RenderType_MainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainComponent, data: {} });
export { RenderType_MainComponent as RenderType_MainComponent };
export function View_MainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container d-flex mx-auto flex-column main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(2, 245760, null, 0, i3.NavbarComponent, [i4.AuthService, i5.DatasetsStore], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "main", [["class", "fill"], ["role", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(7, 114688, null, 0, i8.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_MainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main", [], null, null, null, View_MainComponent_0, RenderType_MainComponent)), i1.ɵdid(1, 49152, null, 0, i9.MainComponent, [], null, null)], null, null); }
var MainComponentNgFactory = i1.ɵccf("app-main", i9.MainComponent, View_MainComponent_Host_0, {}, {}, []);
export { MainComponentNgFactory as MainComponentNgFactory };
