import { CommonModule } from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule, MatExpansionModule, MatIconModule, MatProgressBarModule, MatTooltipModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

import { MapEffects } from './effects/map.effects';
import { MapHistory } from './history/map.history';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MapControlComponent } from './map-control/map-control.component';
import { MapLabelsComponent } from './map-labels/map-labels.component';
import { MapTilesComponent } from './map-tiles/map-tiles.component';
import { OverlayComponent } from './overlay/overlay.component';
import { PointCardComponent } from './point-card/point-card.component';
import { PointListComponent } from './point-list/point-list.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { GroupControlModule } from './group-control/group-control.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    RouterModule,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressBarModule,
    GroupControlModule
  ],
  declarations: [
    DashboardComponent,
    MapTilesComponent,
    MapControlComponent,
    MapLabelsComponent,
    PointCardComponent,
    OverlayComponent,
    PointListComponent,
    SearchBoxComponent
  ],
  exports: [
    MapTilesComponent,
    MapControlComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [MapEffects, MapHistory],
      useFactory: (_e: MapEffects, _h: MapHistory) => () => true
    }
  ]
})
export class MapModule { }
