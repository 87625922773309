import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { Dataset } from 'src/app/datasets/models';
import { DatasetService } from 'src/app/datasets/services/dataset.service';
import { DatasetsStore } from 'src/app/datasets/store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;
  contact = environment.contactEmail;
  datasets$: Observable<Dataset[]>;
  selectedDataset: Dataset;
  stats: { [nature: string]: number } = {};
  total = 0;

  constructor(private route: ActivatedRoute, private datasetService: DatasetService, private store: DatasetsStore) {
    this.datasets$ = this.store.datasets$;
  }

  ngOnInit() {
    this.subscriptions = this.route.fragment.subscribe(fragment => {
      if (fragment) {
        document.querySelector('#datasetsAboutContainer').scrollIntoView({ behavior: 'smooth' });
      }
    });
    this.subscriptions.add(this.datasets$.subscribe(datasets => {
      const fragment = this.route.snapshot.fragment;
      const dataset = datasets.find(d => d.key === fragment);
      if (dataset) {
        this.selectDataset(dataset);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  selectDataset(dataset: Dataset) {
    this.selectedDataset = dataset;
    this.datasetService.getDatasetStats(dataset.key).subscribe(stats => {
      this.stats = stats;
      this.total = this.selectedDataset.natures.reduce((sum, nature) => {
        return sum + (nature.key in stats ? stats[nature.key] : 0);
      }, 0);
    }, _ => {
      this.stats = {};
      this.total = 0;
    });
  }

}
