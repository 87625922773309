import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ViewChild, ViewEncapsulation, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material';

import { GroupFilterComponent } from '../group-filter/group-filter.component';
import { DatasetsStore } from 'src/app/datasets/store';
import { Dataset, FilterValue } from 'src/app/datasets/models';
import { Point } from 'src/app/map/models';

@Component({
  selector: 'app-group-filters',
  templateUrl: './group-filters.component.html',
  styleUrls: ['./group-filters.component.scss']
})
export class GroupFiltersComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription;
  @ViewChildren('filterComponents') filterComponents: QueryList<GroupFilterComponent>;
  dataset: Dataset;
  @Input() data: GroupFiltersDialogData;

  constructor(private dsStore: DatasetsStore) {
  }

  ngOnInit() {
    this.subscriptions = this.dsStore.dataset$.subscribe((dataset: Dataset) => {
      this.dataset = dataset;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getSelectedFilterValues() {
    const filterValues = new Array<FilterValue | FilterValue[]>();
    this.filterComponents.forEach((comp: GroupFilterComponent) => {
      if (comp.filterValue) {
        filterValues.push(comp.filterValue);
      }
    });
    return filterValues;
  }

}

@Component({
  selector: 'app-group-filters-dialog',
  templateUrl: './group-filters-dialog.component.html',
  styleUrls: ['./group-filters-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GroupFiltersDialogComponent {

  @ViewChild('filtersComponent', { static: false }) public filtersComponent: GroupFiltersComponent;
  constructor(public dialogRef: MatDialogRef<GroupFiltersDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: GroupFiltersDialogData) { }

  displayGroup() {
    const filters = this.filtersComponent.getSelectedFilterValues();
    this.dialogRef.close(filters);
  }

  clearGroups() {
    this.dialogRef.close([]);
  }

}

export class GroupFiltersDialogData {
  key: string;
  point: Point;
}
