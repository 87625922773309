import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Point } from 'src/app/map/models';
import { Dataset, FilterValue, Group, GroupFilter, Nature } from 'src/app/datasets/models';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PointService {

  // The url for the datasets api
  private datasetsUrl = environment.apiEndpoint + '/datasets';
  constructor(private http: HttpClient) {
  }

  getNeighbors(dataset: Dataset, point: Point, nature: string) {
    const url = `${this.datasetsUrl}/${dataset.key}/points/${point.id}/${nature}/`;
    return this.http.get<Point[]>(url).pipe(catchError(this.handleError('getting neighbors')));
  }

  fetchPoints(dataset: Dataset, nature: string, limit: string, xrange: number[], yrange: number[],
    query: string, extraQueryString: string = '') {
    const params = this.getHttpParams(nature, limit, xrange, yrange, query, extraQueryString);
    return this.http.get<Point[]>(`${this.datasetsUrl}/${dataset.key}/points/`, { params: params })
      .pipe(catchError(this.handleError('getting points')));
  }

  private getHttpParams(nature: string, limit: string, xrange: number[], yrange: number[],
    query: string, extraQueryString: string): HttpParams {
    let params = new HttpParams({ fromString: extraQueryString });
    if (xrange && yrange && xrange.length === 2 && yrange.length === 2) {
      params = params.set('box', `(${xrange[0]},${yrange[0]}),(${xrange[1]},${yrange[1]})`);
    }
    if (nature) {
      params = params.set('nature', nature);
    }
    if (limit) {
      params = params.set('limit', limit);
    }
    if (query) {
      params = params.set('search', query);
    }
    return params;
  }

  getGroupByReference(dataset: Dataset, groupFilter: GroupFilter, rank: number) {
    let params = new HttpParams().set('field', groupFilter.key).set('rank', rank.toString());
    params = params.set('query', groupFilter.type + '_' + groupFilter.key + '=' + rank.toString());
    return this.http.get<Group>(`${this.datasetsUrl}/${dataset.key}/groupbyreference/`, { params })
      .pipe(catchError(this.handleError('getting group by reference for ' + dataset.key)));
  }

  getDatasetGroup(dataset: Dataset, filters: Array<FilterValue | FilterValue[]>) {
    let params = new HttpParams();
    params = params.set('bitmaps', this.buildBitmapParamString(filters))
      .set('query', this.buildGroupQueryParamString(dataset, filters));
    return this.http.get<Group>(`${this.datasetsUrl}/${dataset.key}/group/`, { params: params })
      .pipe(catchError(this.handleError('getting group for ' + dataset.key)));
  }

  private buildBitmapParamString(filters: Array<FilterValue | FilterValue[]>) {
    return filters.map(filter => {
      if (Array.isArray(filter)) {
        return filter.map(f => f.bitmap_index).sort((a, b) => a - b).join('|');
      } else {
        return filter.bitmap_index.toString();
      }
    }).join('&');
  }

  private buildGroupQueryParamString(dataset: Dataset, filters: Array<FilterValue | FilterValue[]>) {
    const types = dataset.filters.reduce((dict, gf) => { dict[gf.key] = gf.type; return dict; }, {});
    return filters.map(filter => {
      if (Array.isArray(filter)) {
        const field = filter[0].field;
        return types[field] + '_' + field + '=' + filter.map(f => f.rank ? f.rank.toString() : f.value).join(',');
      } else {
        return types[filter.field] + '_' + filter.field + '=' + (filter.rank ? filter.rank.toString() : filter.value);
      }
    }).join('&');
  }

  requestGroupTile(group: Group, zoom: number, x: number, y: number, xend: number, yend: number, layers: Nature[]) {
    const params = new HttpParams().set('layers', layers.map(d => d.key).join());
    return this.http.get<{ string: number[][] }>(`${environment.apiEndpoint}/groups/${group.uuid}/${zoom}/${x}/${y}/${xend}/${yend}/`,
      { params: params })
      .pipe(catchError(this.handleError('requesting tiling for group ' + group.uuid)));
  }


  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError(operation = 'operation') {
    return (error: HttpErrorResponse) => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
      }

      // return an observable with a user-facing error message
      return throwError(`An error occured while ${operation}. Please try again later.`);
    };
  }
}
