import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

import { User } from 'src/app/auth/models/user';
import { Dataset } from 'src/app/datasets/models/dataset';
import { DatasetsStore } from 'src/app/datasets/store';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

  user: User;
  datasets$: Observable<Dataset[]>;
  private subscriptions: Subscription;

  constructor(public authService: AuthService, private store: DatasetsStore) { }

  ngOnInit() {
    this.subscriptions = this.authService.loggedUser.subscribe((user: User) => this.user = user);
    this.datasets$ = this.store.datasets$;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
