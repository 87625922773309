import { scan } from 'd3-array';
import * as d3 from 'd3-selection';
import { collisionArea } from '@d3fc/d3fc-label-layout/src/util/collision';
import { Nature } from 'src/app/datasets/models';
import { Point } from 'src/app/map/models';

/**
* The function to generate labels.
*/
export function textLabel(natures: Array<Nature>, clickCallback) {
  const tLabel = (selection) => {
    selection.style('fill', p => {
      const nature = natures.find(e => e.key === p.nature);
      return nature ? nature.color : 'white';
    });
    selection.each((data: Point, i, group) => {
      const node = group[i];
      const nodeSelection = d3.select(node);
      nodeSelection.attr('class', data.nature + ' label ' + (data.is_cluster ? 'cluster-label' : 'point-label'));

      if (data.is_cluster) {
        // Cluster label
        if (!nodeSelection.select('rect').empty()) {
          nodeSelection.selectAll('*').remove();
        }
        labelCluster(nodeSelection, data);
      } else {
        // Point label
        if (nodeSelection.select('rect').empty()) {
          nodeSelection.selectAll('*').remove();
        }
        labelPoint(node, nodeSelection, data, clickCallback);
      }

      if (clickCallback) {
        nodeSelection.on('click', clickCallback);
      } else {
        nodeSelection.on('click', null);
      }

      nodeSelection
        .classed('selected', data.is_selected)
        .classed('search-point', data.is_search_result);
    });
  };

  return tLabel;
}

function labelPoint(node, nodeSelection, data: Point, clickCallback) {
  const color = nodeSelection.style('fill');
  const width = parseFloat(node.getAttribute('layout-width')) || 0;
  const height = parseFloat(node.getAttribute('layout-height')) || 0;
  const anchorX = parseFloat(node.getAttribute('anchor-x')) || 0;
  const anchorY = parseFloat(node.getAttribute('anchor-y')) || 0;

  let rect = nodeSelection.select('rect');
  if (rect.empty()) {
    rect = nodeSelection.append('rect').attr('rx', 5).attr('ry', 5);
  }
  rect.attr('width', width).attr('height', height);

  let path = nodeSelection.select('path.border-line');
  if (path.empty()) {
    path = nodeSelection.append('path').classed('border-line', true)
      .style('stroke-width', '1.5px').style('fill', 'none');
  }
  path.attr('d', getPathD(anchorX, anchorY, width, height)).style('stroke', color);

  let circle = nodeSelection.select('circle.anchor');
  if (circle.empty()) {
    circle = nodeSelection.append('circle').classed('anchor', true).attr('r', 3);
  }
  circle.attr('cx', anchorX).attr('cy', anchorY);

  let circleShadow = nodeSelection.select('circle.point-border');
  if (circleShadow.empty()) {
    circleShadow = nodeSelection.append('circle').classed('point-border', true)
      .attr('r', 4)
      .attr('fill', 'none')
      .attr('stroke', '#00cbcc');
  }
  circleShadow
    .attr('cx', anchorX)
    .attr('cy', anchorY)
    .attr('stroke-width', data.is_search_result ? '1.8px' : 0);

  if (data.img_data && data.img_data !== '') {
    let image = nodeSelection.select('image.label-container');
    if (image.empty()) {
      image = nodeSelection.append('svg:image').classed('label-container', true).attr('transform', `translate(7, 3)`)
        .attr('width', 28).attr('height', 28);
    }
    image.attr('xlink:href', data.img_data);
  } else {
    let text = nodeSelection.select('text.label-container');
    if (text.empty()) {
      text = nodeSelection.append('text').classed('label-container', true).attr('transform', `translate(7, 3)`);
    }
    text.text(data.label).call(wrap, 100);
  }

  if (data.is_selected) {
    nodeSelection.style('visibility', 'visible');
  } else if (data.is_search_result) {
    if (nodeSelection.style('visibility') === 'hidden') {
      nodeSelection.style('visibility', 'visible').classed('hidden', true);
    }
  }
}

function labelCluster(nodeSelection, data: Point) {
  let node: any;
  if (data.img_data && data.img_data !== '') {
    node = nodeSelection.select('image.cluster-label');
    if (node.empty()) {
      node = nodeSelection.append('svg:image').classed('cluster-label', true)
        .attr('width', 28).attr('height', 28)
        .attr('filter', 'url(#monochromeYellow)');
    }
    node.attr('xlink:href', data.img_data);
  } else {
    node = nodeSelection.select('text.cluster-label');
    if (node.empty()) {
      node = nodeSelection.append('text').classed('cluster-label', true);
    }
    node.text(data.label);
  }

  node.on('mouseover', (p: Point) => {
    d3.select('#theme' + p.id).selectAll('path').style('fill', 'rgb(230, 230, 230)');
  }).on('mouseout', (p: Point) => {
    d3.select('#theme' + p.id).selectAll('path').style('fill', 'none');
  });
}

function getPathD(anchorX, anchorY, width, height) {
  if (anchorX === 0 && anchorY === 0) {
    return 'M0 15 v -10 A 5 5 0 0 1 5 0 h 20';
  } else if (anchorX === width && anchorY === 0) {
    return `M${width - 25} 0 h 20 A 5 5 0 0 1 ${width} 5 v 10`;
  } else if (anchorX === width && anchorY === height) {
    return `M${width} ${height - 15} v 10 A 5 5 0 0 1 ${width - 5} ${height} h -20`;
  } else if (anchorX === 0 && anchorY === height) {
    return `M25 ${height} h -20 A 5 5 0 0 1 0 ${height - 5} v -10`;
  } else if (anchorX === width || anchorX === 0) {
    return `M${anchorX} ${anchorY - 10} v 20`;
  } else if (anchorY === height || anchorY === 0) {
    return `M${anchorX - 15} ${anchorY} h 30`;
  }
  return '';
}

/**
* Function to wrap text inside a text node.
*/
export function wrap(text, width) {
  text.each(function() {
    const textNode = d3.select(this);
    const words = text.text().split(/\s+/).reverse();
    let word, line = [];
    const y = textNode.attr('y'), dy = 1.1;
    let tspan = textNode.text(null).append('tspan').attr('x', 0).attr('y', y).attr('dy', dy + 'em');
    let lineNumber = 0;
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(' '));
      if ((<any>tspan.node()).getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = textNode.append('tspan').attr('x', 0).attr('y', y).attr('dy', dy + 'em').text(word);
        if (++lineNumber > 3) {
          tspan.text(word + '...');
          break;
        }
      }
    }
  });
}

export interface Label {
  // Invoke signature.
  (selection: any): void;
  size: (...args: any[]) => Label;
  position: (...args: any[]) => Label;
  component: (...args: any[]) => Label;
  decorate: (...args: any[]) => Label;
}

/**
* Modified version of layoutLabel.
* From https://github.com/d3fc/d3fc/blob/master/packages/d3fc-label-layout/src/label.js
*/
export function layoutLabel(layoutStrategy): Label {

  let decorate = (g, data, index) => { };
  let size = (d, i, nodes) => [0, 0];
  let position = (d, i, nodes) => [d.x, d.y];
  const strategy = layoutStrategy || ((x) => x);
  let component = () => { };

  const label = <Label>function(selection) {

    selection.each((data, index, group) => {

      const update = d3.select(group[index]).selectAll('g.label')
        .data(data, (_, i) => i.toString());

      const enter = update.enter()
        .append('g')
        .attr('class', 'label');

      update.exit().remove();
      const g = update.merge(enter).call(component);

      // obtain the rectangular bounding boxes for each child
      const nodes = g.nodes();
      const childRects = nodes
        .map((node, i) => {
          const d = <Point>d3.select(node).datum();
          const pos = position(d, i, nodes);
          const childSize = size(d, i, nodes);
          return {
            hidden: false,
            x: pos[0],
            y: pos[1],
            width: childSize[0],
            height: childSize[1]
          };
        });

      // apply the strategy to derive the layout. The strategy does not change the order
      // or number of label.
      const layout = strategy(childRects, data);

      g.style('visibility', (_, i) => layout[i].hidden ? 'hidden' : 'visible')
        .attr('transform', (_, i) => 'translate(' + layout[i].x + ', ' + layout[i].y + ')')
        // set the layout width / height so that children can use SVG layout if required
        .attr('layout-width', (_, i) => layout[i].width)
        .attr('layout-height', (_, i) => layout[i].height)
        .attr('anchor-x', (d, i) => childRects[i].x - layout[i].x)
        .attr('anchor-y', (d, i) => childRects[i].y - layout[i].y);

      g.call(component);

      decorate(g, data, index);
    });
  };

  label.size = (...args) => {
    size = typeof args[0] === 'function' ? args[0] : () => args[0];
    return label;
  };

  label.position = (...args) => {
    position = typeof args[0] === 'function' ? args[0] : () => args[0];
    return label;
  };

  label.component = (...args) => {
    component = args[0];
    return label;
  };

  label.decorate = (...args) => {
    decorate = args[0];
    return label;
  };

  return label;
}

const scanForObject = (array, comparator) => array[scan(array, comparator)];

export const layoutRemoveOverlaps = (adaptedStrategy) => {

  adaptedStrategy = adaptedStrategy || ((x) => x);

  const removeOverlaps = (layout, data: Point[]) => {
    layout = adaptedStrategy(layout);
    layout.forEach((l, i) => l.removable = data[i].is_cluster ? false : true);
    while (true) {
      // find the collision area for all overlapping rectangles, hiding the one
      // with the greatest overlap
      const visible = layout.filter((d) => !d.hidden);
      const collisions = visible.map((d, i) => [d, d.removable ? collisionArea(visible, i) : 0]);
      const maximumCollision = scanForObject(collisions, (a, b) => b[1] - a[1]);
      if (maximumCollision[1] > 0) {
        maximumCollision[0].hidden = true;
      } else {
        break;
      }
    }
    return layout;
  };

  // rebindAll(removeOverlaps, adaptedStrategy);

  return removeOverlaps;
};
