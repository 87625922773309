/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./maintenance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../footer/footer.component.ngfactory";
import * as i3 from "../footer/footer.component";
import * as i4 from "./maintenance.component";
var styles_MaintenanceComponent = [i0.styles];
var RenderType_MaintenanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MaintenanceComponent, data: {} });
export { RenderType_MaintenanceComponent as RenderType_MaintenanceComponent };
export function View_MaintenanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "maintenance-container d-flex flex-column text-white justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["class", "d-inline-block align-top"], ["height", "85"], ["src", "/assets/cartolab.png"], ["width", "85"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cartolabe"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Our website is currently down for maintenance. It should be back shortly."])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "hr", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you think this is taking too long, you can contact us by "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["email."])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-footer", [["class", "maintenance-footer"]], null, null, null, i2.View_FooterComponent_0, i2.RenderType_FooterComponent)), i1.ɵdid(15, 114688, null, 0, i3.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 15, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "mailto:", _co.contact, ""); _ck(_v, 12, 0, currVal_0); }); }
export function View_MaintenanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-maintenance", [], null, null, null, View_MaintenanceComponent_0, RenderType_MaintenanceComponent)), i1.ɵdid(1, 114688, null, 0, i4.MaintenanceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaintenanceComponentNgFactory = i1.ɵccf("app-maintenance", i4.MaintenanceComponent, View_MaintenanceComponent_Host_0, {}, {}, []);
export { MaintenanceComponentNgFactory as MaintenanceComponentNgFactory };
