<nav class="navbar navbar-expand-lg navbar-light bg-light rounded">
  <a class="navbar-brand" href="#">Cartolabe</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbar">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="mapDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Map
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" *ngFor="let dataset of datasets$ | async" [routerLink]="['/map', dataset.key]" routerLinkActive="active">{{dataset.label}}</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" [routerLink]="['/about']" routerLinkActive="active">About</a>
      </li>
    </ul>
    <button type="button" class="btn btn-outline-secondary btn-sm logout" *ngIf="user" (click)="authService.logout()">
      Logout
    </button>
  </div>
</nav>
