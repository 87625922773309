<div class="map-container" #mapcontainer>
  <div class="map-commands d-flex flex-row align-items-start">
    <div class="menu d-flex flex-column">
      <button type="button" class="btn btn-outline-secondary btn-sm zoom-action" matTooltip="Zoom in" matTooltipPosition="right" (click)="doZoom(1)">+</button>
      <button type="button" class="btn btn-outline-secondary btn-sm zoom-action" matTooltip="Zoom out" matTooltipPosition="right" (click)="doZoom(-1)">-</button>

      <div class="zoom-indicator mt-1">
        <div class="zoom zoom-bar zoom0 my-1 mx-auto" [class.active]="zoomBar === 0"></div>
        <div class="zoom zoom-bar zoom1 my-1 mx-auto" [class.active]="zoomBar === 1"></div>
        <div class="zoom zoom-bar zoom2 my-1 mx-auto" [class.active]="zoomBar === 2"></div>
        <div class="zoom zoom-bar zoom3 my-1 mx-auto" [class.active]="zoomBar === 3"></div>
      </div>
      <i class="material-icons menu-icon mt-1" (click)="toggleFullScreen()" *ngIf="!overlay" matTooltip="Toggle fullscreen" matTooltipPosition="right">fullscreen</i>
      <i class="material-icons menu-icon mt-1" (click)="toggleFullScreen()" *ngIf="overlay" matTooltip="Toggle fullscreen" matTooltipPosition="right">fullscreen_exit</i>
      <i class="material-icons menu-icon mt-1" id="settingsBrightnessBtn" matTooltip="Highlight search results">settings_brightness</i>
    </div>
    <div class="menu d-flex flex-column">
      <div class="overlay-box group-control-container ml-4 mb-3" *ngIf="showGroupsCtrl">
        <app-group-control></app-group-control>
      </div>
      <div class="overlay-box search-container ml-4" *ngIf="overlay">
        <div class="mb-2">
          <app-search-box></app-search-box>
        </div>

        <div class="results-container">
          <app-point-list (target)="targetPoint($event)"></app-point-list>
        </div>
      </div>
    </div>
  </div>
  <div class="zoom-container">
    <app-map-tiles #tilesComponent [props]="props" class="tiles-container"></app-map-tiles>
    <app-map-labels #labelsComponent [props]="props" class="labels-container" (fitZoom)="fitZoom($event)"></app-map-labels>
  </div>
</div>
