import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MapService } from 'src/app/map/services';
import { MapStore } from 'src/app/map/store';
import { Point } from 'src/app/map/models';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchBoxComponent implements OnInit {

  showSearchFilters = false;
  filtersHeight = 0;
  searchBoxPoint: Point;
  @ViewChild('selectComponent', {static: false}) selectComponent: NgSelectComponent;

  constructor(public mapService: MapService, public mapStore: MapStore) { }

  ngOnInit() {
  }

  toggleSearchFilters() {
    this.showSearchFilters = !this.showSearchFilters;
    this.filtersHeight = this.showSearchFilters ? 2 : 0;
  }

  /***
   * Use a timeout to trigger a new change detection cycle when clearing the model value.
   * See https://github.com/ng-select/ng-select/issues/302
   */
  searchBoxOnChange(searchResult: Point) {
    this.mapService.selectSearchResult(searchResult);
    setTimeout(() => this.searchBoxPoint = null);
    this.selectComponent.blur();
  }

  searchBoxSelectAll() {
    this.mapService.selectAllSearchResults();
    this.selectComponent.close();
    this.selectComponent.blur();
  }

}
