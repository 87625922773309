import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, take, tap, catchError } from 'rxjs/operators';

import { DatasetService } from 'src/app/datasets/services/dataset.service';
import { DatasetsStore } from 'src/app/datasets/store';

/**
 * Guards are hooks into the route resolution process, providing an opportunity
 * to inform the router's navigation process whether the route should continue
 * to activate this route. Guards must return an observable of true or false.
 */
@Injectable({
  providedIn: 'root',
})
export class DatasetsGuard implements CanActivate {
  constructor(private store: DatasetsStore, private datasetService: DatasetService, private router: Router) { }

  /**
   * This is the actual method the router will call when our guard is run.
   * This guard checks if the datasets have already been loaded. If not,
   * it requests them.
   */
  canActivate(): Observable<boolean> {
    return this.store.loaded$.pipe(
      tap(loaded => {
        if (!loaded) {
          this.datasetService.getDatasets().pipe(
            catchError(() => {
              this.router.navigate(['/maintenance']);
              return of([]);
            })
          ).subscribe(datasets => this.store.setDatasets(datasets));
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
