import { Component, OnInit, Input } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { debounceTime, tap, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { DatasetService } from 'src/app/datasets/services/dataset.service';
import { Dataset, GroupFilter, FilterValue } from 'src/app/datasets/models';
import { Point } from 'src/app/map/models';
import { GroupFiltersDialogData } from '../group-filters/group-filters.component';

@Component({
  selector: 'app-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss']
})
export class GroupFilterComponent implements OnInit {

  @Input() filter: GroupFilter;
  @Input() dataset: Dataset;
  @Input() data: GroupFiltersDialogData;
  filterInput$ = new Subject<string>();
  filterValues$: Observable<FilterValue[]>;
  filterValue: FilterValue[] | FilterValue;
  refFilterValue: FilterValue;
  refPoint: Point;
  filterDistance = 10;
  searchLoading = false;
  rangeSelect = false;
  distanceSelect = false;
  searchSelect = false;

  constructor(public datasetService: DatasetService) { }

  ngOnInit() {
    if (this.filter.type === 'IN' && this.filter.multi === true) {
      this.filterValues$ = this.datasetService.searchFilterValues(this.dataset, this.filter.key, '', '200').pipe(
        map(arr => arr.sort((a, b) => parseInt(a.value, 10) < parseInt(b.value, 10) ? -1 : 1))
      );
      this.rangeSelect = true;
    } else if (this.filter.type === 'DI') {
      if (this.data && this.data.key === this.filter.key && this.data.point && this.data.point.location) {
        this.refPoint = this.data.point;
        this.searchByLocation(this.refPoint.location);
      } else {
        this.initFilterTypeahead();
      }
      this.distanceSelect = true;
    } else {
      this.initFilterTypeahead();
      this.searchSelect = true;
    }
  }

  private initFilterTypeahead() {
    this.filterValues$ = this.filterInput$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searchLoading = true),
      switchMap(query => this.search(query).pipe(
        tap(_ => {
          this.searchLoading = false;
        })
      ))
    );
  }

  search(query: string): Observable<FilterValue[]> {
    if (query && query.trim() !== '') {
      return this.datasetService.searchFilterValues(this.dataset, this.filter.key, query, '40');
    }
    return of([]);
  }

  onRefPointChange($event: FilterValue) {
    this.filterValue = $event;
  }

  onDistanceChanged() {
    if (this.refFilterValue && this.refFilterValue.location) {
      this.searchByLocation(this.refFilterValue.location);
    } else if (this.refPoint && this.refPoint.location) {
      this.searchByLocation(this.refPoint.location);
    }
  }

  private searchByLocation(location: string) {
    this.datasetService.searchFilterValuesByDistance(this.dataset, this.filter.key, location, this.filterDistance)
      .subscribe(results => {
        this.filterValue = results;
        // console.log(results);
      });
  }

}
