import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Point } from 'src/app/map/models';
import { MapService } from 'src/app/map/services';
import { MapStore } from 'src/app/map/store';


@Component({
  selector: 'app-point-list',
  templateUrl: './point-list.component.html',
  styleUrls: ['./point-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PointListComponent implements OnInit, OnDestroy {

  @Output() target = new EventEmitter<Point>();
  selectedPoint: Point;
  searchPoints$: Observable<Point[]>;
  searchTitle$: Observable<string>;
  searchCount$: Observable<string>;
  private subscriptions: Subscription;

  constructor(public mapService: MapService, private mapStore: MapStore) {
    this.searchTitle$ = this.mapStore.searchTitle$.pipe(
      map(title => title.length > 80 ? title.substring(0, 80) + '...' : title)
    );
    this.searchCount$ = this.mapStore.searchPoints$.pipe(
      map(points => points.length > 0 ? ' (' + points.length + ')' : '')
    );
    this.searchPoints$ = this.mapStore.searchPoints$;
  }

  ngOnInit() {
    this.subscriptions = this.mapStore.selectedPoint$.subscribe(point => this.selectedPoint = point);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onPointCardAction(point: Point, action: string) {
    if (action === 'target' && point) {
      this.target.emit(point);
    } else if (action === 'clear') {
      this.mapStore.setSelectedPoint(null);
    } else {
      this.mapService.getNeighbors(point, action);
    }
  }

  selectPoint(point: Point) {
    this.mapStore.setSelectedPoint(point);
  }

  clearSearchResults() {
    this.mapService.clearSearchResults();
  }

}
