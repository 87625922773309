<footer class="mt-2">
  <div class="d-flex flex-row align-items-center">
    <span class="p-2 font-weight-light">
      Developped by
    </span>
    <a href="https://www.inria.fr/" class="p-2">
      <img src="/assets/inria.png" width="116" height="50" class="d-inline-block align-top" alt="inria">
    </a>
    <a href="https://www.lri.fr/" class="p-2">
      <img src="/assets/cnrs.svg" width="50" height="50" class="d-inline-block align-top" alt="cnrs">
    </a>
    <a href="http://www.u-psud.fr" class="p-2">
      <img src="/assets/LogoUPSUD_2014_SANS_SIGN_UPSaclay_couleur.jpg" height="50" class="d-inline-bloc align-top" alt="upsud">
    </a>
    <div class="copyright p-2 ml-auto font-weight-light">
      {{version}}
      &nbsp;&copy;
      {{now | date: 'yyyy'}} Cartolabe
    </div>
  </div>
</footer>
