<div class="group-control" (click)="openDialog()">
  <div class="group-control-header d-flex flex-row">
    <div class="truncated">
      {{breadcrumbs}}
    </div>
    <button type="button" class="close ml-3" aria-label="Close" (click)="clearGroups($event)" *ngIf="grouped">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
