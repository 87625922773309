import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PointService = /** @class */ (function () {
    function PointService(http) {
        this.http = http;
        // The url for the datasets api
        this.datasetsUrl = environment.apiEndpoint + '/datasets';
    }
    PointService.prototype.getNeighbors = function (dataset, point, nature) {
        var url = this.datasetsUrl + "/" + dataset.key + "/points/" + point.id + "/" + nature + "/";
        return this.http.get(url).pipe(catchError(this.handleError('getting neighbors')));
    };
    PointService.prototype.fetchPoints = function (dataset, nature, limit, xrange, yrange, query, extraQueryString) {
        if (extraQueryString === void 0) { extraQueryString = ''; }
        var params = this.getHttpParams(nature, limit, xrange, yrange, query, extraQueryString);
        return this.http.get(this.datasetsUrl + "/" + dataset.key + "/points/", { params: params })
            .pipe(catchError(this.handleError('getting points')));
    };
    PointService.prototype.getHttpParams = function (nature, limit, xrange, yrange, query, extraQueryString) {
        var params = new HttpParams({ fromString: extraQueryString });
        if (xrange && yrange && xrange.length === 2 && yrange.length === 2) {
            params = params.set('box', "(" + xrange[0] + "," + yrange[0] + "),(" + xrange[1] + "," + yrange[1] + ")");
        }
        if (nature) {
            params = params.set('nature', nature);
        }
        if (limit) {
            params = params.set('limit', limit);
        }
        if (query) {
            params = params.set('search', query);
        }
        return params;
    };
    PointService.prototype.getGroupByReference = function (dataset, groupFilter, rank) {
        var params = new HttpParams().set('field', groupFilter.key).set('rank', rank.toString());
        params = params.set('query', groupFilter.type + '_' + groupFilter.key + '=' + rank.toString());
        return this.http.get(this.datasetsUrl + "/" + dataset.key + "/groupbyreference/", { params: params })
            .pipe(catchError(this.handleError('getting group by reference for ' + dataset.key)));
    };
    PointService.prototype.getDatasetGroup = function (dataset, filters) {
        var params = new HttpParams();
        params = params.set('bitmaps', this.buildBitmapParamString(filters))
            .set('query', this.buildGroupQueryParamString(dataset, filters));
        return this.http.get(this.datasetsUrl + "/" + dataset.key + "/group/", { params: params })
            .pipe(catchError(this.handleError('getting group for ' + dataset.key)));
    };
    PointService.prototype.buildBitmapParamString = function (filters) {
        return filters.map(function (filter) {
            if (Array.isArray(filter)) {
                return filter.map(function (f) { return f.bitmap_index; }).sort(function (a, b) { return a - b; }).join('|');
            }
            else {
                return filter.bitmap_index.toString();
            }
        }).join('&');
    };
    PointService.prototype.buildGroupQueryParamString = function (dataset, filters) {
        var types = dataset.filters.reduce(function (dict, gf) { dict[gf.key] = gf.type; return dict; }, {});
        return filters.map(function (filter) {
            if (Array.isArray(filter)) {
                var field = filter[0].field;
                return types[field] + '_' + field + '=' + filter.map(function (f) { return f.rank ? f.rank.toString() : f.value; }).join(',');
            }
            else {
                return types[filter.field] + '_' + filter.field + '=' + (filter.rank ? filter.rank.toString() : filter.value);
            }
        }).join('&');
    };
    PointService.prototype.requestGroupTile = function (group, zoom, x, y, xend, yend, layers) {
        var params = new HttpParams().set('layers', layers.map(function (d) { return d.key; }).join());
        return this.http.get(environment.apiEndpoint + "/groups/" + group.uuid + "/" + zoom + "/" + x + "/" + y + "/" + xend + "/" + yend + "/", { params: params })
            .pipe(catchError(this.handleError('requesting tiling for group ' + group.uuid)));
    };
    /**
    * Handle Http operation that failed.
    * Let the app continue.
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
    PointService.prototype.handleError = function (operation) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred. Handle it accordingly.
                console.error('An error occurred:', error.error.message);
            }
            else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                console.error("Backend returned code " + error.status + ", body was: " + error.error);
            }
            // return an observable with a user-facing error message
            return throwError("An error occured while " + operation + ". Please try again later.");
        };
    };
    PointService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PointService_Factory() { return new PointService(i0.ɵɵinject(i1.HttpClient)); }, token: PointService, providedIn: "root" });
    return PointService;
}());
export { PointService };
