/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./point-list.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../point-card/point-card.component.ngfactory";
import * as i3 from "../point-card/point-card.component";
import * as i4 from "../services/map.service";
import * as i5 from "../../datasets/store/datasets.store";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/common";
import * as i8 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/material/tooltip";
import * as i11 from "@angular/cdk/overlay";
import * as i12 from "@angular/cdk/scrolling";
import * as i13 from "@angular/cdk/platform";
import * as i14 from "@angular/cdk/a11y";
import * as i15 from "@angular/cdk/bidi";
import * as i16 from "@angular/platform-browser";
import * as i17 from "./point-list.component";
import * as i18 from "../store/map.store";
var styles_PointListComponent = [i0.styles];
var RenderType_PointListComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PointListComponent, data: {} });
export { RenderType_PointListComponent as RenderType_PointListComponent };
function View_PointListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mb-4 selected-point"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-point-card", [], null, [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.onPointCardAction(_co.selectedPoint, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PointCardComponent_0, i2.RenderType_PointCardComponent)), i1.ɵdid(2, 638976, null, 0, i3.PointCardComponent, [i4.MapService, i5.DatasetsStore, i6.MatDialog], { point: [0, "point"], active: [1, "active"], open: [2, "open"] }, { action: "action" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedPoint; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_PointListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "search-title font-weight-light d-flex flex-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "mr-1 search-title-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "search-title-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mat-icon", [["class", "ml-auto search-clear mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSearchResults() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(9, 16777216, null, null, 3, "mat-icon", [["class", "mat-icon notranslate"], ["matTooltip", "Search results are shown on the map with a dot highlighted in cyan. Use the brightness control button on the map to highlight only search results."], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), i1.ɵdid(11, 212992, null, 0, i10.MatTooltip, [i11.Overlay, i1.ElementRef, i12.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i13.Platform, i14.AriaDescriber, i14.FocusMonitor, i10.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i15.Directionality], [2, i10.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i16.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(-1, 0, ["info"]))], function (_ck, _v) { _ck(_v, 7, 0); _ck(_v, 10, 0); var currVal_6 = "Search results are shown on the map with a dot highlighted in cyan. Use the brightness control button on the map to highlight only search results."; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.searchCount$)); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 7).inline; var currVal_3 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 10).inline; var currVal_5 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_4, currVal_5); }); }
function View_PointListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-point-card", [], null, [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.onPointCardAction(_v.parent.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PointCardComponent_0, i2.RenderType_PointCardComponent)), i1.ɵdid(1, 638976, null, 0, i3.PointCardComponent, [i4.MapService, i5.DatasetsStore, i6.MatDialog], { point: [0, "point"], open: [1, "open"] }, { action: "action" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PointListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mt-1 point-result"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectPoint(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PointListComponent_4)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.selectedPoint == null) ? null : _co.selectedPoint.id) !== _v.context.$implicit.id); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PointListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "point-list-container d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PointListComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PointListComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "search-results-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PointListComponent_3)), i1.ɵdid(9, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedPoint; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.searchTitle$)); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.searchPoints$)); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_PointListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-point-list", [], null, null, null, View_PointListComponent_0, RenderType_PointListComponent)), i1.ɵdid(1, 245760, null, 0, i17.PointListComponent, [i4.MapService, i18.MapStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PointListComponentNgFactory = i1.ɵccf("app-point-list", i17.PointListComponent, View_PointListComponent_Host_0, {}, { target: "target" }, []);
export { PointListComponentNgFactory as PointListComponentNgFactory };
