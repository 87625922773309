import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DatasetsGuard } from 'src/app/datasets/guards';
import { DashboardComponent } from 'src/app/map/dashboard/dashboard.component';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { MaintenanceComponent } from 'src/app/core/maintenance/maintenance.component';
import { AboutComponent } from 'src/app/core/about/about.component';
import { MainComponent } from 'src/app/core/main/main.component';
import { OverlayComponent } from 'src/app/map/overlay/overlay.component';

const appRoutes: Routes = [
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'overlay/:key', component: OverlayComponent, canActivate: [DatasetsGuard] },
  {
    path: '',
    component: MainComponent,
    canActivate: [DatasetsGuard],
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'about', component: AboutComponent },
      { path: 'map/:key', component: DashboardComponent },
      { path: '', redirectTo: `/map/${environment.defaultDatasetKey}`, pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
