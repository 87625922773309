<mat-progress-bar mode="indeterminate" class="group-loading-bar" color="warn" [hidden]="!tiling"></mat-progress-bar>
<svg class="tiles-svg">
  <defs class="tile-filters">
    <filter id="monochromeBlue" color-interpolation-filters="sRGB" x="0" y="0" height="100%" width="100%">
      <feColorMatrix type="matrix" values="0.95 0 0 0 0.05
                  0.85 0 0 0 0.15
                  0.50 0 0 0 0.50
                  0 0 0 1 0" />
    </filter>
    <filter id="monochromeRed" color-interpolation-filters="sRGB" x="0" y="0" height="100%" width="100%">
      <feColorMatrix type="matrix" values="0.10 0 0 0 0.90 0.95 0 0 0 0.05 0.95 0 0 0 0.05 0 0 0 1 0" />
    </filter>
    <filter id="monochromeGreen" color-interpolation-filters="sRGB" x="0" y="0" height="100%" width="100%">
      <feColorMatrix type="matrix"
        values="0 0.10 0 0 0.05
                0 0.80 0 0 0.50
                0 0.10 0 0 0.05
                0 0 0 1 0" />
    </filter>
  </defs>
  <g class="group-layers blend-isolate">

  </g>
  <g class="tile-layers blend-isolate">

  </g>
</svg>
