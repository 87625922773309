import { Injectable } from '@angular/core';
import { of, Observable, Subject, from } from 'rxjs';
import { debounceTime, tap, distinctUntilChanged, switchMap, mergeMap } from 'rxjs/operators';
import { PointService } from './point.service';
import { Point, MapViewboxBounds } from '../models';
import { MapStore } from 'src/app/map/store';
import { DatasetsStore } from 'src/app/datasets/store';
import { Nature, Group, FilterValue, GroupFilter } from 'src/app/datasets/models';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  _clusterCache: { [key: string]: Point[] } = {};

  typeaheadInput: string;
  typeaheadInput$ = new Subject<string>();
  typeaheadPoints$: Observable<Point[]>;
  typeaheadResults: Array<Point> = [];
  searchLoading = false;

  constructor(private pointService: PointService, private mapStore: MapStore, private dsStore: DatasetsStore) {
    this.typeaheadPoints$ = this.typeaheadInput$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap((val) => {
        this.searchLoading = true;
        this.typeaheadInput = val;
      }),
      switchMap(query => this.search(query).pipe(
        tap((results) => {
          this.typeaheadResults = results;
          this.searchLoading = false;
        })
      ))
    );
  }

  resetService() {
    this._clusterCache = {};
    this.typeaheadResults = [];
    this.typeaheadInput$.next('');
  }

  /**
  * Search for points within bounds.
  */
  searchInBounds(bounds: MapViewboxBounds) {
    const dataset = this.dsStore.dataset;
    let obs = of([]);
    if (dataset && dataset.id) {
      const natures = this.mapStore.searchFilters.filter(f => f.checked).map(f => f.key).join(',');
      obs = this.pointService.fetchPoints(dataset, natures, '10', bounds.xrange, bounds.yrange,
        null, this.mapStore.groupQueryString);
    }
    obs.subscribe(points => this.mapStore.setSearchPoints(points, 'Highest ranked points in search zone'));
  }

  /**
  * Get new points within viewBox.
  */
  updateViewBox(bounds: MapViewboxBounds, fetchThemes: boolean) {
    const dataset = this.dsStore.dataset;
    this.mapStore.resetViewBoxPoints();
    let obs = of([]);
    if (dataset && dataset.id) {
      if (fetchThemes) {
        this._getClusters(bounds.zoom);
      }
      const natures = this.dsStore.labels.filter(n => n.checked && n.limit > 0);
      obs = from(natures).pipe(
        mergeMap(nature => this.pointService.fetchPoints(dataset, nature.key, String(nature.limit), bounds.xrange, bounds.yrange,
          null, this.mapStore.groupQueryString))
      );
    }
    obs.subscribe(points => this.mapStore.addViewBoxPoints(points));
  }

  private search(query: string): Observable<Point[]> {
    const dataset = this.dsStore.dataset;
    if (dataset && dataset.id && query) {
      const natures = this.mapStore.searchFilters.filter(f => f.checked).map(f => f.key).join(',');
      return this.pointService.fetchPoints(dataset, natures, '20', null, null, query, this.mapStore.groupQueryString);
    }
    return of([]);
  }

  selectSearchResult(point: Point) {
    if (point && point.id) {
      this.mapStore.setSelectedPoint(point, true);
    }
  }

  selectAllSearchResults() {
    if (this.typeaheadResults.length > 0) {
      const title = 'Search results for ' + this.typeaheadInput;
      this.mapStore.setSearchPoints(this.typeaheadResults, title);
    }
  }

  clearSearchResults() {
    this.mapStore.setSearchPoints([], '');
  }

  getNeighbors(point: Point, nature: string) {
    const dataset = this.dsStore.dataset;
    this.mapStore.setSelectedPoint(point);
    this.pointService.getNeighbors(dataset, point, nature)
      .subscribe(points => this.mapStore.setSearchPoints(points, `Nearest ${nature} for ${point.label}`));
  }

  /**
  * Get the clusters either from cache or from the server.
  */
  _getClusters(zoom: number) {
    if (zoom > 80) {
      this.mapStore.clearClusters();
      return;
    }
    let nature = 'hl_clusters', limit = '20';
    if (zoom > 28) {
      nature = 'vll_clusters';
      limit = '512';
    } else if (zoom > 8) {
      nature = 'll_clusters';
      limit = '128';
    } else if (zoom > 2.5) {
      nature = 'ml_clusters';
      limit = '32';
    }
    if (nature in this._clusterCache) {
      this.mapStore.setClusters(this._clusterCache[nature]);
    } else {
      const dataset = this.dsStore.dataset;
      this.pointService.fetchPoints(dataset, nature, limit, null, null, null).subscribe(points => {
        points.forEach(p => p.is_cluster = true);
        this._clusterCache[nature] = points;
        this.mapStore.setClusters(points);
      });
    }
  }

  clearGroup() {
    this.mapStore.clearGroup();
  }

  displayGroup(filters: Array<FilterValue | FilterValue[]>) {
    if (filters.length > 0) {
      const dataset = this.dsStore.dataset;
      this.pointService.getDatasetGroup(dataset, filters).subscribe(group => this.mapStore.setGroup([group], filters));
    }
  }

  displayGroupForPoint(groupFilter: GroupFilter, point: Point) {
    const dataset = this.dsStore.dataset;
    this.pointService.getGroupByReference(dataset, groupFilter, point.rank).subscribe(group => this.mapStore.setGroup([group],
      [{ field: groupFilter.key, value: point.label, rank: point.rank, bitmap_index: undefined, location: undefined }]
    ));
  }

  requestGroupTile(group: Group, zoom: number, x: number, y: number, xend: number, yend: number, layers: Nature[]) {
    if (layers.length === 0) {
      return of({});
    }
    return this.pointService.requestGroupTile(group, zoom, x, y, xend, yend, layers);
  }

}
