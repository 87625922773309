import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MainComponent } from './main/main.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { AboutComponent } from './about/about.component';

export const COMPONENTS = [
  FooterComponent,
  NavbarComponent,
  MainComponent,
  MaintenanceComponent,
  AboutComponent
];

@NgModule({
  imports: [CommonModule, RouterModule, MatListModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class CoreModule { }
