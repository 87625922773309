import { Router } from '@angular/router';
import { of } from 'rxjs';
import { filter, take, tap, catchError } from 'rxjs/operators';
import { DatasetService } from 'src/app/datasets/services/dataset.service';
import { DatasetsStore } from 'src/app/datasets/store';
import * as i0 from "@angular/core";
import * as i1 from "../store/datasets.store";
import * as i2 from "../services/dataset.service";
import * as i3 from "@angular/router";
/**
 * Guards are hooks into the route resolution process, providing an opportunity
 * to inform the router's navigation process whether the route should continue
 * to activate this route. Guards must return an observable of true or false.
 */
var DatasetsGuard = /** @class */ (function () {
    function DatasetsGuard(store, datasetService, router) {
        this.store = store;
        this.datasetService = datasetService;
        this.router = router;
    }
    /**
     * This is the actual method the router will call when our guard is run.
     * This guard checks if the datasets have already been loaded. If not,
     * it requests them.
     */
    DatasetsGuard.prototype.canActivate = function () {
        var _this = this;
        return this.store.loaded$.pipe(tap(function (loaded) {
            if (!loaded) {
                _this.datasetService.getDatasets().pipe(catchError(function () {
                    _this.router.navigate(['/maintenance']);
                    return of([]);
                })).subscribe(function (datasets) { return _this.store.setDatasets(datasets); });
            }
        }), filter(function (loaded) { return loaded; }), take(1));
    };
    DatasetsGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DatasetsGuard_Factory() { return new DatasetsGuard(i0.ɵɵinject(i1.DatasetsStore), i0.ɵɵinject(i2.DatasetService), i0.ɵɵinject(i3.Router)); }, token: DatasetsGuard, providedIn: "root" });
    return DatasetsGuard;
}());
export { DatasetsGuard };
