/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./group-filters.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../group-filter/group-filter.component.ngfactory";
import * as i3 from "../group-filter/group-filter.component";
import * as i4 from "../../../datasets/services/dataset.service";
import * as i5 from "@angular/common";
import * as i6 from "./group-filters.component";
import * as i7 from "../../../datasets/store/datasets.store";
import * as i8 from "./group-filters-dialog.component.scss.ngstyle";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
var styles_GroupFiltersComponent = [i0.styles];
var RenderType_GroupFiltersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupFiltersComponent, data: {} });
export { RenderType_GroupFiltersComponent as RenderType_GroupFiltersComponent };
function View_GroupFiltersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "group-filter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-group-filter", [], null, null, null, i2.View_GroupFilterComponent_0, i2.RenderType_GroupFilterComponent)), i1.ɵdid(2, 114688, [[1, 4], ["filterComponents", 4]], 0, i3.GroupFilterComponent, [i4.DatasetService], { filter: [0, "filter"], dataset: [1, "dataset"], data: [2, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.dataset; var currVal_2 = _co.data; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_GroupFiltersComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { filterComponents: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupFiltersComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataset.filters; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GroupFiltersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-group-filters", [], null, null, null, View_GroupFiltersComponent_0, RenderType_GroupFiltersComponent)), i1.ɵdid(1, 245760, null, 0, i6.GroupFiltersComponent, [i7.DatasetsStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GroupFiltersComponentNgFactory = i1.ɵccf("app-group-filters", i6.GroupFiltersComponent, View_GroupFiltersComponent_Host_0, { data: "data" }, {}, []);
export { GroupFiltersComponentNgFactory as GroupFiltersComponentNgFactory };
var styles_GroupFiltersDialogComponent = [i8.styles];
var RenderType_GroupFiltersDialogComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GroupFiltersDialogComponent, data: {} });
export { RenderType_GroupFiltersDialogComponent as RenderType_GroupFiltersDialogComponent };
export function View_GroupFiltersDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { filtersComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i9.MatDialogTitle, [[2, i9.MatDialogRef], i1.ElementRef, i9.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, [" Filter points "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["aria-label", "Close"], ["class", "close ml-3"], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).dialogRef.close(i1.ɵnov(_v, 5).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 606208, null, 0, i9.MatDialogClose, [[2, i9.MatDialogRef], i1.ElementRef, i9.MatDialog], { ariaLabel: [0, "ariaLabel"], type: [1, "type"], dialogResult: [2, "dialogResult"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "mat-dialog-content", [["class", "mb-4 mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i9.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "small", [["class", "help text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Filtering points will highlight them on the map and restrict search functions to this selection. "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-group-filters", [], null, null, null, View_GroupFiltersComponent_0, RenderType_GroupFiltersComponent)), i1.ɵdid(13, 245760, [[1, 4], ["filtersComponent", 4]], 0, i6.GroupFiltersComponent, [i7.DatasetsStore], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 7, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(15, 16384, null, 0, i9.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearGroups() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Clear"])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.displayGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(20, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Filter"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_3 = "Close"; var currVal_4 = "button"; var currVal_5 = ""; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.data; _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).ariaLabel || null); var currVal_2 = i1.ɵnov(_v, 5).type; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_7 = (i1.ɵnov(_v, 17).disabled || null); var currVal_8 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_7, currVal_8); var currVal_9 = (i1.ɵnov(_v, 20).disabled || null); var currVal_10 = (i1.ɵnov(_v, 20)._animationMode === "NoopAnimations"); _ck(_v, 19, 0, currVal_9, currVal_10); }); }
export function View_GroupFiltersDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-group-filters-dialog", [], null, null, null, View_GroupFiltersDialogComponent_0, RenderType_GroupFiltersDialogComponent)), i1.ɵdid(1, 49152, null, 0, i6.GroupFiltersDialogComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA], null, null)], null, null); }
var GroupFiltersDialogComponentNgFactory = i1.ɵccf("app-group-filters-dialog", i6.GroupFiltersDialogComponent, View_GroupFiltersDialogComponent_Host_0, {}, {}, []);
export { GroupFiltersDialogComponentNgFactory as GroupFiltersDialogComponentNgFactory };
