<div class="background">
  <div class="overlay">
    <div class="container-fluid">
      <div class="maintenance-container d-flex flex-column text-white justify-content-center align-items-center">
        <h1>
          <img src="/assets/cartolab.png" width="85" height="85" class="d-inline-block align-top" alt="">
          Cartolabe</h1>
        <h2>Our website is currently down for maintenance. It should be back shortly.</h2>
        <hr class="divider">
        <p class="lead">If you think this is taking too long, you can contact us by <a href="mailto:{{contact}}">email.</a></p>
      </div>
      <app-footer class="maintenance-footer"></app-footer>
    </div>
  </div>
</div>
