import { Point } from 'src/app/map/models';
import { Group, FilterValue } from 'src/app/datasets/models';

export class MapState {
  selectedPoint: Point;
  searchPoints: Point[];
  searchTitle: string;
  groups: Group[];
  filters: Array<FilterValue | FilterValue[]>;
  datasetKey: string;

  constructor(datasetKey: string = '', selectedPoint: Point = null, searchPoints: Point[] = [], searchTitle: string = '',
    groups: Group[] = [], filters: Array<FilterValue | FilterValue[]> = []) {
    this.datasetKey = datasetKey;
    this.selectedPoint = selectedPoint;
    this.searchPoints = searchPoints;
    this.searchTitle = searchTitle;
    this.groups = groups;
    this.filters = filters;
  }

  static equal(a: MapState, b: MapState) {
    if (a === b) {
      return true;
    }
    if (!(a && b)) {
      return false;
    }
    if (a.datasetKey !== b.datasetKey) {
      return false;
    }
    if (a.searchTitle !== b.searchTitle) {
      return false;
    }
    if ((a.selectedPoint === null || b.selectedPoint === null) && a.selectedPoint !== b.selectedPoint) {
      return false;
    } else if (a.selectedPoint !== null && b.selectedPoint !== null && a.selectedPoint.id !== b.selectedPoint.id) {
      return false;
    }
    if (a.searchPoints.length !== b.searchPoints.length) {
      return false;
    } else {
      for (let i = 0; i < a.searchPoints.length; i++) {
        if (a.searchPoints[i].id !== b.searchPoints[i].id) {
          return false;
        }
      }
    }
    if (a.groups.length !== b.groups.length) {
      return false;
    } else {
      for (let i = 0; i < a.groups.length; i++) {
        if (a.groups[i].uuid !== b.groups[i].uuid) {
          return false;
        }
      }
    }
    return true;
  }

  isEmpty(): boolean {
    return this.selectedPoint === null && this.searchPoints.length === 0 && this.searchTitle === '' && this.groups.length === 0;
  }
}
