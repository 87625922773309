import { BehaviorSubject } from 'rxjs';
import * as Collections from 'typescript-collections';
import { SearchFilter } from 'src/app/datasets/models';
import * as i0 from "@angular/core";
var MapStore = /** @class */ (function () {
    function MapStore() {
        this._viewBoxPoints$ = new BehaviorSubject([]);
        this._selectedPoint$ = new BehaviorSubject(null);
        this._searchPoints$ = new BehaviorSubject([]);
        this._groups$ = new BehaviorSubject([]);
        this._filters$ = new BehaviorSubject([]);
        this._searchTitle$ = new BehaviorSubject('');
        this._searchFilters$ = new BehaviorSubject([]);
        this._clusterPoints = new Array();
        this._labelPoints = new Array();
        this.viewBoxPoints$ = this._viewBoxPoints$.asObservable();
        this.selectedPoint$ = this._selectedPoint$.asObservable();
        this.searchPoints$ = this._searchPoints$.asObservable();
        this.groups$ = this._groups$.asObservable();
        this.filters$ = this._filters$.asObservable();
        this.searchTitle$ = this._searchTitle$.asObservable();
        this.searchFilters$ = this._searchFilters$.asObservable();
    }
    MapStore.prototype.resetViewBoxPoints = function () {
        this._clusterPoints = [];
        this._labelPoints = [];
    };
    MapStore.prototype.addViewBoxPoints = function (points) {
        this._labelPoints = this._labelPoints.concat(points);
        this._viewBoxPoints$.next(points);
    };
    MapStore.prototype.setClusters = function (points) {
        this._clusterPoints = points;
        this._labelPoints = this._labelPoints.concat(points);
        this._viewBoxPoints$.next(points);
    };
    MapStore.prototype.clearClusters = function () {
        this._clusterPoints = [];
    };
    MapStore.prototype.setDataset = function (dataset, resetState) {
        if (resetState === void 0) { resetState = true; }
        this._clusterPoints = [];
        this._labelPoints = [];
        if (resetState) {
            this._filters$.next([]);
            this._searchTitle$.next('');
            this._groups$.next([]);
            this._selectedPoint$.next(null);
            this._searchPoints$.next([]);
        }
        if (dataset && dataset.natures) {
            this._searchFilters$.next(dataset.natures.filter(function (p) { return p.checked; })
                .map(function (p) { return new SearchFilter(p.label, p.key, p.limit > 0); }));
        }
        else {
            this._searchFilters$.next([]);
        }
    };
    MapStore.prototype.setSelectedPoint = function (point, redraw) {
        if (redraw === void 0) { redraw = false; }
        var previous = this._selectedPoint$.getValue();
        if (previous && previous.is_selected) {
            previous.is_selected = false;
        }
        if (point) {
            point.is_selected = true;
        }
        this._selectedPoint$.next(point);
        if (redraw) {
            this._viewBoxPoints$.next([point]);
        }
    };
    Object.defineProperty(MapStore.prototype, "selectedPoint", {
        get: function () {
            return this._selectedPoint$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    MapStore.prototype.setSearchPoints = function (points, title) {
        this._searchTitle$.next(title);
        this._searchPoints$.next(points);
    };
    Object.defineProperty(MapStore.prototype, "searchPoints", {
        get: function () {
            return this._searchPoints$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapStore.prototype, "searchTitle", {
        get: function () {
            return this._searchTitle$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapStore.prototype, "groupQueryString", {
        get: function () {
            var groups = this._groups$.getValue();
            if (groups.length > 0) {
                return groups[0].query;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapStore.prototype, "clusterPoints", {
        get: function () {
            return this._clusterPoints;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapStore.prototype, "labelPoints", {
        get: function () {
            var points = new Collections.Set(function (p) { return p.id; });
            // Add the selectedPoint first
            var selectedPoint = this._selectedPoint$.getValue();
            if (selectedPoint) {
                // Force is_search_result to false for selected point so that it displays properly
                selectedPoint.is_search_result = false;
                points.add(selectedPoint);
            }
            // Add the searchPoints first to make sure they're all added to the set.
            var searchPoints = this._searchPoints$.getValue();
            if (searchPoints && searchPoints.length > 0) {
                searchPoints.forEach(function (p) {
                    p.is_search_result = true;
                    points.add(p);
                });
            }
            this._labelPoints.forEach(function (p) { return points.add(p); });
            return points;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapStore.prototype, "searchFilters", {
        get: function () {
            return this._searchFilters$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    MapStore.prototype.clearGroup = function () {
        this._groups$.next([]);
        this._filters$.next([]);
    };
    MapStore.prototype.setGroup = function (groups, filters) {
        this._filters$.next(filters);
        this._groups$.next(groups);
    };
    Object.defineProperty(MapStore.prototype, "groups", {
        get: function () {
            return this._groups$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapStore.prototype, "filters", {
        get: function () {
            return this._filters$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    MapStore.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapStore_Factory() { return new MapStore(); }, token: MapStore, providedIn: "root" });
    return MapStore;
}());
export { MapStore };
