export interface Nature {
  id: number;
  label: string;
  key: string;
  checked: boolean;
  layer: boolean;
  inactive: boolean;
  limit: number;
  color: string;
  icon: string;
  tooltip: string;
  layer_tooltip: string;
  extra_fields: ExtraField[];
}

export interface ExtraField {
  label: string;
  key: string;
  tooltip: string;
}

export interface GroupFilter {
  label: string;
  key: string;
  natures: string;
  type: string;
  multi: boolean;
}

 export interface FilterValue {
   field: string;
   value: string;
   rank: number;
   bitmap_index: number;
   location: string;
 }

 export interface Group {
   uuid: string;
   bitmaps: string;
   query: string;
 }

 export class SearchFilter {
  label: string;
  key: string;
  checked: boolean;

  constructor(label: string, key: string, checked: boolean) {
    this.label = label;
    this.key = key;
    this.checked = checked;
  }
}

export class Dataset {
  id: number;
  label: string;
  key: string;
  private: boolean;
  description: string;
  about_description: string;
  analysed_on: Date;
  tiled_on: Date;
  max_zoom: number;
  working: boolean;
  metadata: { [key: string]: any};
  natures: Array<Nature>;
  filters: Array<GroupFilter>;

  static equal(a: Dataset, b: Dataset) {
    if (a === b) {
      return true;
    }
    if (!(a && b)) {
      return false;
    }
    return a.id === b.id;
  }
}
