import { scan } from 'd3-array';
import * as d3 from 'd3-selection';
import { collisionArea } from '@d3fc/d3fc-label-layout/src/util/collision';
/**
* The function to generate labels.
*/
export function textLabel(natures, clickCallback) {
    var tLabel = function (selection) {
        selection.style('fill', function (p) {
            var nature = natures.find(function (e) { return e.key === p.nature; });
            return nature ? nature.color : 'white';
        });
        selection.each(function (data, i, group) {
            var node = group[i];
            var nodeSelection = d3.select(node);
            nodeSelection.attr('class', data.nature + ' label ' + (data.is_cluster ? 'cluster-label' : 'point-label'));
            if (data.is_cluster) {
                // Cluster label
                if (!nodeSelection.select('rect').empty()) {
                    nodeSelection.selectAll('*').remove();
                }
                labelCluster(nodeSelection, data);
            }
            else {
                // Point label
                if (nodeSelection.select('rect').empty()) {
                    nodeSelection.selectAll('*').remove();
                }
                labelPoint(node, nodeSelection, data, clickCallback);
            }
            if (clickCallback) {
                nodeSelection.on('click', clickCallback);
            }
            else {
                nodeSelection.on('click', null);
            }
            nodeSelection
                .classed('selected', data.is_selected)
                .classed('search-point', data.is_search_result);
        });
    };
    return tLabel;
}
function labelPoint(node, nodeSelection, data, clickCallback) {
    var color = nodeSelection.style('fill');
    var width = parseFloat(node.getAttribute('layout-width')) || 0;
    var height = parseFloat(node.getAttribute('layout-height')) || 0;
    var anchorX = parseFloat(node.getAttribute('anchor-x')) || 0;
    var anchorY = parseFloat(node.getAttribute('anchor-y')) || 0;
    var rect = nodeSelection.select('rect');
    if (rect.empty()) {
        rect = nodeSelection.append('rect').attr('rx', 5).attr('ry', 5);
    }
    rect.attr('width', width).attr('height', height);
    var path = nodeSelection.select('path.border-line');
    if (path.empty()) {
        path = nodeSelection.append('path').classed('border-line', true)
            .style('stroke-width', '1.5px').style('fill', 'none');
    }
    path.attr('d', getPathD(anchorX, anchorY, width, height)).style('stroke', color);
    var circle = nodeSelection.select('circle.anchor');
    if (circle.empty()) {
        circle = nodeSelection.append('circle').classed('anchor', true).attr('r', 3);
    }
    circle.attr('cx', anchorX).attr('cy', anchorY);
    var circleShadow = nodeSelection.select('circle.point-border');
    if (circleShadow.empty()) {
        circleShadow = nodeSelection.append('circle').classed('point-border', true)
            .attr('r', 4)
            .attr('fill', 'none')
            .attr('stroke', '#00cbcc');
    }
    circleShadow
        .attr('cx', anchorX)
        .attr('cy', anchorY)
        .attr('stroke-width', data.is_search_result ? '1.8px' : 0);
    if (data.img_data && data.img_data !== '') {
        var image = nodeSelection.select('image.label-container');
        if (image.empty()) {
            image = nodeSelection.append('svg:image').classed('label-container', true).attr('transform', "translate(7, 3)")
                .attr('width', 28).attr('height', 28);
        }
        image.attr('xlink:href', data.img_data);
    }
    else {
        var text = nodeSelection.select('text.label-container');
        if (text.empty()) {
            text = nodeSelection.append('text').classed('label-container', true).attr('transform', "translate(7, 3)");
        }
        text.text(data.label).call(wrap, 100);
    }
    if (data.is_selected) {
        nodeSelection.style('visibility', 'visible');
    }
    else if (data.is_search_result) {
        if (nodeSelection.style('visibility') === 'hidden') {
            nodeSelection.style('visibility', 'visible').classed('hidden', true);
        }
    }
}
function labelCluster(nodeSelection, data) {
    var node;
    if (data.img_data && data.img_data !== '') {
        node = nodeSelection.select('image.cluster-label');
        if (node.empty()) {
            node = nodeSelection.append('svg:image').classed('cluster-label', true)
                .attr('width', 28).attr('height', 28)
                .attr('filter', 'url(#monochromeYellow)');
        }
        node.attr('xlink:href', data.img_data);
    }
    else {
        node = nodeSelection.select('text.cluster-label');
        if (node.empty()) {
            node = nodeSelection.append('text').classed('cluster-label', true);
        }
        node.text(data.label);
    }
    node.on('mouseover', function (p) {
        d3.select('#theme' + p.id).selectAll('path').style('fill', 'rgb(230, 230, 230)');
    }).on('mouseout', function (p) {
        d3.select('#theme' + p.id).selectAll('path').style('fill', 'none');
    });
}
function getPathD(anchorX, anchorY, width, height) {
    if (anchorX === 0 && anchorY === 0) {
        return 'M0 15 v -10 A 5 5 0 0 1 5 0 h 20';
    }
    else if (anchorX === width && anchorY === 0) {
        return "M" + (width - 25) + " 0 h 20 A 5 5 0 0 1 " + width + " 5 v 10";
    }
    else if (anchorX === width && anchorY === height) {
        return "M" + width + " " + (height - 15) + " v 10 A 5 5 0 0 1 " + (width - 5) + " " + height + " h -20";
    }
    else if (anchorX === 0 && anchorY === height) {
        return "M25 " + height + " h -20 A 5 5 0 0 1 0 " + (height - 5) + " v -10";
    }
    else if (anchorX === width || anchorX === 0) {
        return "M" + anchorX + " " + (anchorY - 10) + " v 20";
    }
    else if (anchorY === height || anchorY === 0) {
        return "M" + (anchorX - 15) + " " + anchorY + " h 30";
    }
    return '';
}
/**
* Function to wrap text inside a text node.
*/
export function wrap(text, width) {
    text.each(function () {
        var textNode = d3.select(this);
        var words = text.text().split(/\s+/).reverse();
        var word, line = [];
        var y = textNode.attr('y'), dy = 1.1;
        var tspan = textNode.text(null).append('tspan').attr('x', 0).attr('y', y).attr('dy', dy + 'em');
        var lineNumber = 0;
        while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(' '));
            if (tspan.node().getComputedTextLength() > width) {
                line.pop();
                tspan.text(line.join(' '));
                line = [word];
                tspan = textNode.append('tspan').attr('x', 0).attr('y', y).attr('dy', dy + 'em').text(word);
                if (++lineNumber > 3) {
                    tspan.text(word + '...');
                    break;
                }
            }
        }
    });
}
/**
* Modified version of layoutLabel.
* From https://github.com/d3fc/d3fc/blob/master/packages/d3fc-label-layout/src/label.js
*/
export function layoutLabel(layoutStrategy) {
    var decorate = function (g, data, index) { };
    var size = function (d, i, nodes) { return [0, 0]; };
    var position = function (d, i, nodes) { return [d.x, d.y]; };
    var strategy = layoutStrategy || (function (x) { return x; });
    var component = function () { };
    var label = function (selection) {
        selection.each(function (data, index, group) {
            var update = d3.select(group[index]).selectAll('g.label')
                .data(data, function (_, i) { return i.toString(); });
            var enter = update.enter()
                .append('g')
                .attr('class', 'label');
            update.exit().remove();
            var g = update.merge(enter).call(component);
            // obtain the rectangular bounding boxes for each child
            var nodes = g.nodes();
            var childRects = nodes
                .map(function (node, i) {
                var d = d3.select(node).datum();
                var pos = position(d, i, nodes);
                var childSize = size(d, i, nodes);
                return {
                    hidden: false,
                    x: pos[0],
                    y: pos[1],
                    width: childSize[0],
                    height: childSize[1]
                };
            });
            // apply the strategy to derive the layout. The strategy does not change the order
            // or number of label.
            var layout = strategy(childRects, data);
            g.style('visibility', function (_, i) { return layout[i].hidden ? 'hidden' : 'visible'; })
                .attr('transform', function (_, i) { return 'translate(' + layout[i].x + ', ' + layout[i].y + ')'; })
                // set the layout width / height so that children can use SVG layout if required
                .attr('layout-width', function (_, i) { return layout[i].width; })
                .attr('layout-height', function (_, i) { return layout[i].height; })
                .attr('anchor-x', function (d, i) { return childRects[i].x - layout[i].x; })
                .attr('anchor-y', function (d, i) { return childRects[i].y - layout[i].y; });
            g.call(component);
            decorate(g, data, index);
        });
    };
    label.size = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        size = typeof args[0] === 'function' ? args[0] : function () { return args[0]; };
        return label;
    };
    label.position = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        position = typeof args[0] === 'function' ? args[0] : function () { return args[0]; };
        return label;
    };
    label.component = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        component = args[0];
        return label;
    };
    label.decorate = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        decorate = args[0];
        return label;
    };
    return label;
}
var scanForObject = function (array, comparator) { return array[scan(array, comparator)]; };
var ɵ0 = scanForObject;
export var layoutRemoveOverlaps = function (adaptedStrategy) {
    adaptedStrategy = adaptedStrategy || (function (x) { return x; });
    var removeOverlaps = function (layout, data) {
        layout = adaptedStrategy(layout);
        layout.forEach(function (l, i) { return l.removable = data[i].is_cluster ? false : true; });
        var _loop_1 = function () {
            // find the collision area for all overlapping rectangles, hiding the one
            // with the greatest overlap
            var visible = layout.filter(function (d) { return !d.hidden; });
            var collisions = visible.map(function (d, i) { return [d, d.removable ? collisionArea(visible, i) : 0]; });
            var maximumCollision = scanForObject(collisions, function (a, b) { return b[1] - a[1]; });
            if (maximumCollision[1] > 0) {
                maximumCollision[0].hidden = true;
            }
            else {
                return "break";
            }
        };
        while (true) {
            var state_1 = _loop_1();
            if (state_1 === "break")
                break;
        }
        return layout;
    };
    // rebindAll(removeOverlaps, adaptedStrategy);
    return removeOverlaps;
};
export { ɵ0 };
