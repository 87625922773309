import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import * as d3Color from 'd3-color';
import * as d3 from 'd3-selection';
import * as d3Tile from 'd3-tile';
import * as d3Zoom from 'd3-zoom';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Dataset, Nature, Group } from 'src/app/datasets/models';
import { DatasetsStore } from 'src/app/datasets/store';
import { MapStore } from 'src/app/map/store';
import { MapProperties } from 'src/app/map/models';
import { MapService } from 'src/app/map/services';

@Component({
  selector: 'app-map-tiles',
  templateUrl: './map-tiles.component.html',
  styleUrls: ['./map-tiles.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapTilesComponent implements OnInit, OnDestroy {

  @Input() props: MapProperties;
  private tilesUrl = environment.staticEndpoint + '/tiles/';
  private groupTilesUrl = environment.staticEndpoint + '/grouptiles/';
  private svg: d3.Selection<SVGElement, {}, HTMLElement, any>;
  private tile: any;
  private rasterLayers: d3.Selection<SVGElement, Nature, SVGElement, any>;
  private groupLayers: d3.Selection<SVGElement, Group, SVGElement, any>;
  private groupTileLayers: d3.Selection<SVGElement, Nature, SVGElement, Group>;
  private tiles: any;
  private datasetKey: string;
  private subscriptions: Subscription;
  private debouncedZoom$ = new Subject<d3Zoom.ZoomTransform>();
  tiling = false;
  private groupVisible = false;

  constructor(private store: DatasetsStore, private mapStore: MapStore, private location: Location,
    private mapService: MapService) {
  }

  ngOnInit() {
    this.subscriptions = this.store.dataset$.subscribe((dataset: Dataset) => {
      this.datasetKey = dataset ? dataset.key : null;
    });
    this.subscriptions.add(this.store.layers$.subscribe(_ => this.setupLayers()));
    this.subscriptions.add(this.debouncedZoom$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(_ => this.getNewTiles()));
    this.subscriptions.add(
      this.mapStore.groups$.subscribe((groups) => {
        this.groupVisible = groups.length > 0;
        this.drawGroupLayers();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initSvg() {
    this.tile = d3Tile.tile().size([this.props.width, this.props.height]).wrap(false);
    this.svg = d3.select<SVGElement, {}>('svg.tiles-svg')
      .attr('preserveAspectRatio', 'none');
    this.setupLayers();
  }

  private setupLayers() {
    this.createFeColorFilters();
    this.drawLayers();
    this.drawGroupLayers();
  }

  drawLayers() {
    if (this.svg) {
      this.rasterLayers = this.svg.select<SVGElement>('g.tile-layers')
        .selectAll<SVGElement, Nature>('g.tile-layer')
        .data(this.store.layers.filter(d => !d.inactive), (d: Nature) => d.id.toString())
        .join(
          enter => enter.append<SVGElement>('g').attr('class', d => d.key + '-layer')
            .classed('tile-layer blend-multiply', true),
          update => update,
          exit => exit.remove()
        );
      this.styleTileLayers();
    }
    this.tileLayers();
  }

  styleTileLayers() {
    this.svg.select<SVGElement>('g.tile-layers')
      .style('opacity', _ => this.groupVisible ? 0.2 : 1)
      .selectAll<SVGElement, Nature>('g.tile-layer')
      .attr('filter', d => this.groupVisible ? '' : 'url(' + this.location.path() + '#' + d.key + 'filter)');
  }

  drawGroupLayers() {
    if (this.svg) {
      this.groupLayers = this.svg.select<SVGElement>('g.group-layers')
        .selectAll<SVGElement, Group>('g.group-layer')
        .data(this.mapStore.groups, (d: Group) => d.uuid)
        .join(
          enter => enter.append<SVGElement>('g').attr('id', (d: Group) => 'g' + d.uuid).classed('group-layer', true)
        );
      this.groupTileLayers = this.groupLayers
        .selectAll<SVGElement, Nature>('g.group-tile-layer')
        .data(this.store.layers.filter(d => !d.inactive), (d: Nature) => d.id.toString())
        .join(
          enter => enter.append<SVGElement>('g').attr('class', d => d.key + '-layer')
            .classed('group-tile-layer blend-multiply', true)
            .attr('filter', d => 'url(' + this.location.path() + '#' + d.key + 'filter-saturate)'),
          update => update,
          exit => exit.remove()
        );
      this.styleTileLayers();
    }
    this.tileGroupLayers();
    this.getNewTiles();
  }

  zoomed(transform: d3Zoom.ZoomTransform) {
    const t = transform.translate(this.props.width / 2, this.props.height / 2).scale(512);
    this.tiles = this.tile.scale(t.k).translate([t.x, t.y])();
    this.tileLayers();
    this.tileGroupLayers();
    this.debouncedZoom$.next(transform);
  }

  private tileLayers() {
    if (this.rasterLayers && this.tiles) {
      this.rasterLayers.attr('transform', stringify(this.tiles.scale, this.tiles.translate));
      this.rasterLayers.each((raster: Nature, i: number, groups: SVGElement[]) => {
        const images = d3.select<SVGElement, Nature>(groups[i]).selectAll<SVGElement, any>('image')
          .data(this.tiles, (d: Tile) => [d.tx, d.ty, d.z].join());
        images.join('image')
          .attr('xlink:href', (d: Tile) => this.buildTileUrl(raster.key, d.z, d.x, d.y))
          .attr('x', (d: Tile) => d.tx)
          .attr('y', (d: Tile) => d.ty)
          .attr('width', 256)
          .attr('height', 256);
      });
    }
  }

  private tileGroupLayers() {
    if (this.groupLayers && this.tiles) {
      this.groupLayers.attr('transform', stringify(this.tiles.scale, this.tiles.translate));
      this.groupTileLayers.each((_: Nature, i: number, groups: SVGElement[]) => {
        const images = d3.select(groups[i]).selectAll('image').data(this.tiles, (d: Tile) => [d.tx, d.ty, d.z].join());
        images.join('image')
          .attr('x', (d: Tile) => d.tx)
          .attr('y', (d: Tile) => d.ty)
          .attr('width', 256)
          .attr('height', 256)
          .on('error', () => d3.select(d3.event.currentTarget).attr('xlink:href', 'assets/blank.png'));
      });
    }
  }

  private getNewTiles() {
    if (this.groupLayers && this.tiles) {
      this.groupLayers.each((group: Group) => {
        this.tiling = true;
        const z = this.tiles[0].z, x = this.tiles[0].x, y = this.tiles[0].y,
          xend = this.tiles[this.tiles.length - 1].x, yend = this.tiles[this.tiles.length - 1].y;
        this.mapService.requestGroupTile(group, z, x, y, xend, yend, this.store.layers.filter(d => !d.inactive))
          .pipe(tap(() => this.tiling = false))
          .subscribe((tilesMask) => {
            for (const [layer, mask] of Object.entries(tilesMask)) {
              this.svg.select('#g' + group.uuid).select('g.' + layer + '-layer').selectAll('image')
                .attr('xlink:href', (d: Tile) => {
                  if (mask[d.x - x][d.y - y] === 1.0) {
                    return this.buildGroupTileUrl(group.uuid, layer, d.z, d.x, d.y);
                  }
                  return '';
                });
            }
          }, () => this.tiling = false);
      });
    }
  }

  private buildTileUrl(layerKey: string, zoom: number, x: number, y: number) {
    return `${this.tilesUrl}${this.datasetKey}/${layerKey}/${zoom}/${x}/${y}.png`;
  }

  private buildGroupTileUrl(groupUuid: string, layer: string, zoom: number, x: number, y: number) {
    return `${this.groupTilesUrl}${groupUuid}/${layer}/${zoom}/${x}/${y}.png`;
  }

  setDimedMode(mode: boolean) {
    this.svg.classed('dimed', mode);
  }

  private createFeColorFilters() {
    if (this.svg) {
      this.svg.select<SVGElement>('defs').selectAll<SVGElement, Nature>('g.layer-filter')
        .data(this.store.layers, (d: Nature) => d.id.toString())
        .join(
          enter => appendFeColorFilters(enter),
          update => update,
          exit => exit.remove()
        );
    }
  }
}

function appendFeColorFilters(enter: d3.Selection<d3.EnterElement, Nature, SVGElement, {}>) {
  const sel = enter.append('g').classed('layer-filter', true);
  styleFeColorFilter(sel.append('filter'), false);
  styleFeColorFilter(sel.append('filter'), true);
  return sel;
}

function styleFeColorFilter(filter: d3.Selection<SVGElement, Nature, SVGElement, {}>, saturate: boolean) {
  filter.attr('id', (d: Nature) => d.key + 'filter' + (saturate ? '-saturate' : ''))
    .attr('color-interpolation-filters', 'sRGB')
    .attr('x', 0).attr('y', 0).attr('height', '100%').attr('width', '100%')
    .append('feColorMatrix').attr('type', 'matrix')
    .attr('values', (d: Nature) => {
      let c = d3Color.color(d.color).rgb();
      if (!saturate) {
        c = c.brighter(1);
      }
  return `0 0 0 0 ${(c.r / 255).toFixed(2)}
          0 0 0 0 ${(c.g / 255).toFixed(2)} 
          0 0 0 0 ${(c.b / 255).toFixed(2)} 
          1 0 0 0 0`;
    });
}

function stringify(scale: number, translate: number[]) {
  const k = scale / 256;
  const r = scale % 1 ? Number : Math.round;
  return 'translate(' + r(translate[0] * scale) + ',' + r(translate[1] * scale) + ') scale(' + k + ')';
}

interface Tile {
  x: number;
  y: number;
  z: number;
  tx: number;
  ty: number;
}
