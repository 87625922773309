import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DatasetService = /** @class */ (function () {
    function DatasetService(http) {
        this.http = http;
        this.datasetsUrl = environment.apiEndpoint + '/datasets/';
    }
    DatasetService.prototype.getDatasets = function () {
        return this.http.get(this.datasetsUrl)
            .pipe(catchError(this.handleError('getting datasets')));
    };
    DatasetService.prototype.getDataset = function (key) {
        return this.http.get("" + this.datasetsUrl + key + "/")
            .pipe(catchError(this.handleError('getting dataset ' + key)));
    };
    DatasetService.prototype.searchFilterValues = function (dataset, filterKey, filterValue, limit) {
        var params = new HttpParams();
        params = params.set(filterKey, filterValue);
        params = params.set('limit', limit);
        return this.http.get("" + this.datasetsUrl + dataset.key + "/filtervalues/", { params: params })
            .pipe(catchError(this.handleError('getting filter values for ' + dataset.key)));
    };
    DatasetService.prototype.searchFilterValuesByDistance = function (dataset, filterKey, location, distance) {
        var params = new HttpParams();
        params = params.set('filterKey', filterKey);
        params = params.set('distance', distance.toString());
        params = params.set('location', location);
        return this.http.get("" + this.datasetsUrl + dataset.key + "/filtervalues/", { params: params })
            .pipe(catchError(this.handleError('getting filter values for ' + dataset.key)));
    };
    DatasetService.prototype.getDatasetStats = function (key) {
        return this.http.get("" + this.datasetsUrl + key + "/stats/")
            .pipe(catchError(this.handleError('getting stats for dataset ' + key)));
    };
    /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
    DatasetService.prototype.handleError = function (operation) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred. Handle it accordingly.
                console.error('An error occurred:', error.error.message);
            }
            else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                console.error(error.message);
                // console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
            }
            // return an observable with a user-facing error message
            return throwError("An error occured while " + operation + ". Please try again later.");
        };
    };
    DatasetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DatasetService_Factory() { return new DatasetService(i0.ɵɵinject(i1.HttpClient)); }, token: DatasetService, providedIn: "root" });
    return DatasetService;
}());
export { DatasetService };
