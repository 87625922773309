import { OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MapStore } from 'src/app/map/store';
import { DatasetsStore } from 'src/app/datasets/store';
import { MapState } from './map.state';
import * as i0 from "@angular/core";
import * as i1 from "../store/map.store";
import * as i2 from "@angular/common";
import * as i3 from "../../datasets/store/datasets.store";
var mapStateKey = 'mapState';
var MapHistory = /** @class */ (function () {
    function MapHistory(mapStore, location, dsStore) {
        var _this = this;
        this.mapStore = mapStore;
        this.location = location;
        this.dsStore = dsStore;
        this.present = new MapState();
        this.subscribed = true;
        this.storeSubscription = merge(this.mapStore.selectedPoint$, this.mapStore.searchPoints$, this.mapStore.groups$).pipe(filter(function (_) { return _this.dsStore.dataset && _this.subscribed; })).subscribe(function (_) { return _this.saveState(); });
        this.historySubscription = this.location.subscribe(function (e) {
            if (e.state && mapStateKey in e.state) {
                // console.groupCollapsed('[MapHistory] Load MapState from history');
                // console.log(e.state[mapStateKey]);
                // console.groupEnd();
                _this.present = e.state[mapStateKey];
                _this.setStoreState();
            }
        });
    }
    MapHistory.prototype.resetHistory = function () {
        this.present = new MapState();
    };
    MapHistory.prototype.saveState = function () {
        var _a;
        var state = new MapState(this.dsStore.dataset.key, this.mapStore.selectedPoint, this.mapStore.searchPoints, this.mapStore.searchTitle, this.mapStore.groups, this.mapStore.filters);
        if (!MapState.equal(this.present, state) && !state.isEmpty()) {
            this.present = state;
            // console.groupCollapsed('[MapHistory] Save MapState to history');
            // console.log(this.present);
            // console.groupEnd();
            this.location.go(this.location.path(), '', (_a = {}, _a[mapStateKey] = this.present, _a));
        }
    };
    MapHistory.prototype.setStoreState = function () {
        this.subscribed = false;
        this.mapStore.setSelectedPoint(this.present.selectedPoint);
        this.mapStore.setSearchPoints(this.present.searchPoints, this.present.searchTitle);
        this.mapStore.setGroup(this.present.groups, this.present.filters);
        this.subscribed = true;
    };
    MapHistory.prototype.disable = function () {
        this.subscribed = false;
    };
    MapHistory.prototype.enable = function () {
        this.subscribed = true;
    };
    MapHistory.prototype.ngOnDestroy = function () {
        this.storeSubscription.unsubscribe();
        this.historySubscription.unsubscribe();
    };
    MapHistory.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapHistory_Factory() { return new MapHistory(i0.ɵɵinject(i1.MapStore), i0.ɵɵinject(i2.Location), i0.ɵɵinject(i3.DatasetsStore)); }, token: MapHistory, providedIn: "root" });
    return MapHistory;
}());
export { MapHistory };
