import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material';
import { GroupFilterComponent } from './group-filter/group-filter.component';
import { GroupFiltersComponent, GroupFiltersDialogComponent } from './group-filters/group-filters.component';
import { GroupControlComponent } from './group-control.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatSliderModule
  ],
  declarations: [
    GroupFiltersComponent,
    GroupFiltersDialogComponent,
    GroupFilterComponent,
    GroupControlComponent
  ],
  entryComponents: [
    GroupFiltersDialogComponent
  ],
  exports: [
    GroupControlComponent
  ]
})
export class GroupControlModule { }
