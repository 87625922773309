/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-labels.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./map-labels.component";
import * as i3 from "../services/map.service";
import * as i4 from "../store/map.store";
import * as i5 from "../../datasets/store/datasets.store";
var styles_MapLabelsComponent = [i0.styles];
var RenderType_MapLabelsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MapLabelsComponent, data: {} });
export { RenderType_MapLabelsComponent as RenderType_MapLabelsComponent };
export function View_MapLabelsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, ":svg:svg", [["class", "labels-svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, ":svg:defs", [["class", "labels-filters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:filter", [["color-interpolation-filters", "sRGB"], ["height", "100%"], ["id", "monochromeYellow"], ["width", "100%"], ["x", "0"], ["y", "0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:feColorMatrix", [["type", "matrix"], ["values", "0 0.45 0 0 0.20\n                0 0.45 0 0 0.20\n                0 0.10 0 0 0.05\n                0 0 0 1 0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:g", [["class", "voronoi-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:g", [["class", "labels-g"]], null, null, null, null, null))], null, null); }
export function View_MapLabelsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map-labels", [], null, null, null, View_MapLabelsComponent_0, RenderType_MapLabelsComponent)), i1.ɵdid(1, 245760, null, 0, i2.MapLabelsComponent, [i3.MapService, i4.MapStore, i5.DatasetsStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapLabelsComponentNgFactory = i1.ɵccf("app-map-labels", i2.MapLabelsComponent, View_MapLabelsComponent_Host_0, { props: "props" }, { fitZoom: "fitZoom" }, []);
export { MapLabelsComponentNgFactory as MapLabelsComponentNgFactory };
