import { BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import * as i0 from "@angular/core";
var predictionNature = {
    id: 99999, label: 'Prediction', key: 'prediction', checked: false, limit: 0, color: '#455a64', icon: 'emoji_objects',
    tooltip: '', layer: false, inactive: false, layer_tooltip: '', extra_fields: []
};
var DatasetsStore = /** @class */ (function () {
    function DatasetsStore() {
        this._dataset$ = new BehaviorSubject(null);
        this._datasets$ = new BehaviorSubject([]);
        this._loaded$ = new BehaviorSubject(false);
        this._natures$ = new BehaviorSubject([]);
        this.dataset$ = this._dataset$.asObservable();
        this.datasets$ = this._datasets$.asObservable();
        this.loaded$ = this._loaded$.asObservable();
        this._natures = this._natures$.asObservable();
    }
    DatasetsStore.prototype.setDatasets = function (datasets) {
        this._datasets$.next(datasets);
        this._loaded$.next(true);
    };
    DatasetsStore.prototype.setDataset = function (key) {
        var dataset = this._datasets$.getValue().find(function (d) { return d.key === key; });
        this._dataset$.next(dataset);
        this._natures$.next(dataset && dataset.natures ? dataset.natures : []);
    };
    Object.defineProperty(DatasetsStore.prototype, "dataset", {
        get: function () {
            return this._dataset$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatasetsStore.prototype, "natures$", {
        get: function () {
            return this._natures.pipe(map(function (natures) { return [predictionNature].concat(natures); }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatasetsStore.prototype, "natures", {
        get: function () {
            return [predictionNature].concat(this._dataset$.getValue().natures);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatasetsStore.prototype, "labels$", {
        get: function () {
            return this._natures.pipe(map(function (natures) { return natures.filter(function (p) { return p.checked; }); }), distinctUntilChanged());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatasetsStore.prototype, "labels", {
        get: function () {
            return this._dataset$.getValue().natures.filter(function (p) { return p.checked; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatasetsStore.prototype, "layers$", {
        get: function () {
            return this._natures.pipe(map(function (natures) { return natures.filter(function (p) { return p.layer; }); }), distinctUntilChanged());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatasetsStore.prototype, "layers", {
        get: function () {
            return this._dataset$.getValue().natures.filter(function (p) { return p.layer; });
        },
        enumerable: true,
        configurable: true
    });
    DatasetsStore.prototype.toggleLayer = function (key) {
        var layer = this._dataset$.getValue().natures.find(function (n) { return n.key === key; });
        layer.inactive = !layer.inactive;
        this._natures$.next(this._dataset$.getValue().natures);
    };
    DatasetsStore.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DatasetsStore_Factory() { return new DatasetsStore(); }, token: DatasetsStore, providedIn: "root" });
    return DatasetsStore;
}());
export { DatasetsStore };
