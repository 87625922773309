/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-control.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../group-control/group-control.component.ngfactory";
import * as i10 from "../group-control/group-control.component";
import * as i11 from "../services/map.service";
import * as i12 from "../store/map.store";
import * as i13 from "@angular/material/dialog";
import * as i14 from "../search-box/search-box.component.ngfactory";
import * as i15 from "../search-box/search-box.component";
import * as i16 from "../point-list/point-list.component.ngfactory";
import * as i17 from "../point-list/point-list.component";
import * as i18 from "@angular/common";
import * as i19 from "../map-tiles/map-tiles.component.ngfactory";
import * as i20 from "../map-tiles/map-tiles.component";
import * as i21 from "../../datasets/store/datasets.store";
import * as i22 from "../map-labels/map-labels.component.ngfactory";
import * as i23 from "../map-labels/map-labels.component";
import * as i24 from "./map-control.component";
import * as i25 from "@angular/router";
var styles_MapControlComponent = [i0.styles];
var RenderType_MapControlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapControlComponent, data: {} });
export { RenderType_MapControlComponent as RenderType_MapControlComponent };
function View_MapControlComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "i", [["class", "material-icons menu-icon mt-1"], ["matTooltip", "Toggle fullscreen"], ["matTooltipPosition", "right"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.toggleFullScreen() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"] }, null), (_l()(), i1.ɵted(-1, null, ["fullscreen"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = "right"; var currVal_1 = "Toggle fullscreen"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MapControlComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "i", [["class", "material-icons menu-icon mt-1"], ["matTooltip", "Toggle fullscreen"], ["matTooltipPosition", "right"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.toggleFullScreen() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"] }, null), (_l()(), i1.ɵted(-1, null, ["fullscreen_exit"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = "right"; var currVal_1 = "Toggle fullscreen"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MapControlComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "overlay-box group-control-container ml-4 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-group-control", [], null, null, null, i9.View_GroupControlComponent_0, i9.RenderType_GroupControlComponent)), i1.ɵdid(2, 245760, null, 0, i10.GroupControlComponent, [i11.MapService, i12.MapStore, i13.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_MapControlComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "overlay-box search-container ml-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-search-box", [], null, null, null, i14.View_SearchBoxComponent_0, i14.RenderType_SearchBoxComponent)), i1.ɵdid(3, 114688, null, 0, i15.SearchBoxComponent, [i11.MapService, i12.MapStore], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "results-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-point-list", [], null, [[null, "target"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("target" === en)) {
        var pd_0 = (_co.targetPoint($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_PointListComponent_0, i16.RenderType_PointListComponent)), i1.ɵdid(6, 245760, null, 0, i17.PointListComponent, [i11.MapService, i12.MapStore], null, { target: "target" })], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); }, null); }
export function View_MapControlComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { tilesComponent: 0 }), i1.ɵqud(671088640, 2, { labelsComponent: 0 }), (_l()(), i1.ɵeld(2, 0, [["mapcontainer", 1]], null, 30, "div", [["class", "map-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 24, "div", [["class", "map-commands d-flex flex-row align-items-start"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 18, "div", [["class", "menu d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 2, "button", [["class", "btn btn-outline-secondary btn-sm zoom-action"], ["matTooltip", "Zoom in"], ["matTooltipPosition", "right"], ["type", "button"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.doZoom(1) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"] }, null), (_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵeld(8, 16777216, null, null, 2, "button", [["class", "btn btn-outline-secondary btn-sm zoom-action"], ["matTooltip", "Zoom out"], ["matTooltipPosition", "right"], ["type", "button"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.doZoom((0 - 1)) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"] }, null), (_l()(), i1.ɵted(-1, null, ["-"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "zoom-indicator mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "zoom zoom-bar zoom0 my-1 mx-auto"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "zoom zoom-bar zoom1 my-1 mx-auto"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "zoom zoom-bar zoom2 my-1 mx-auto"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "zoom zoom-bar zoom3 my-1 mx-auto"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapControlComponent_1)), i1.ɵdid(17, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapControlComponent_2)), i1.ɵdid(19, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 16777216, null, null, 2, "i", [["class", "material-icons menu-icon mt-1"], ["id", "settingsBrightnessBtn"], ["matTooltip", "Highlight search results"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 21)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 21)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(-1, null, ["settings_brightness"])), (_l()(), i1.ɵeld(23, 0, null, null, 4, "div", [["class", "menu d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapControlComponent_3)), i1.ɵdid(25, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapControlComponent_4)), i1.ɵdid(27, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(28, 0, null, null, 4, "div", [["class", "zoom-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "app-map-tiles", [["class", "tiles-container"]], null, null, null, i19.View_MapTilesComponent_0, i19.RenderType_MapTilesComponent)), i1.ɵdid(30, 245760, [[1, 4], ["tilesComponent", 4]], 0, i20.MapTilesComponent, [i21.DatasetsStore, i12.MapStore, i18.Location, i11.MapService], { props: [0, "props"] }, null), (_l()(), i1.ɵeld(31, 0, null, null, 1, "app-map-labels", [["class", "labels-container"]], null, [[null, "fitZoom"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fitZoom" === en)) {
        var pd_0 = (_co.fitZoom($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i22.View_MapLabelsComponent_0, i22.RenderType_MapLabelsComponent)), i1.ɵdid(32, 245760, [[2, 4], ["labelsComponent", 4]], 0, i23.MapLabelsComponent, [i11.MapService, i12.MapStore, i21.DatasetsStore], { props: [0, "props"] }, { fitZoom: "fitZoom" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "right"; var currVal_1 = "Zoom in"; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = "right"; var currVal_3 = "Zoom out"; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_8 = !_co.overlay; _ck(_v, 17, 0, currVal_8); var currVal_9 = _co.overlay; _ck(_v, 19, 0, currVal_9); var currVal_10 = "Highlight search results"; _ck(_v, 21, 0, currVal_10); var currVal_11 = _co.showGroupsCtrl; _ck(_v, 25, 0, currVal_11); var currVal_12 = _co.overlay; _ck(_v, 27, 0, currVal_12); var currVal_13 = _co.props; _ck(_v, 30, 0, currVal_13); var currVal_14 = _co.props; _ck(_v, 32, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.zoomBar === 0); _ck(_v, 12, 0, currVal_4); var currVal_5 = (_co.zoomBar === 1); _ck(_v, 13, 0, currVal_5); var currVal_6 = (_co.zoomBar === 2); _ck(_v, 14, 0, currVal_6); var currVal_7 = (_co.zoomBar === 3); _ck(_v, 15, 0, currVal_7); }); }
export function View_MapControlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map-control", [], null, null, null, View_MapControlComponent_0, RenderType_MapControlComponent)), i1.ɵdid(1, 4440064, null, 0, i24.MapControlComponent, [i25.Router, i25.ActivatedRoute, i21.DatasetsStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapControlComponentNgFactory = i1.ɵccf("app-map-control", i24.MapControlComponent, View_MapControlComponent_Host_0, { overlay: "overlay" }, {}, []);
export { MapControlComponentNgFactory as MapControlComponentNgFactory };
