<div class="container">
  <div class="card text-center login-card">
    <div class="card-header">
      <h4>Login</h4>
    </div>
    <div class="card-body">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-row mb-4">
          <div class="input-group">
            <div class="input-group-prepend mr-3 pt-2">
              <i class="material-icons">email</i>
            </div>
            <input type="email" class="form-control" placeholder="Email" aria-label="email" formControlName="email" required>
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="input-group">
            <div class="input-group-prepend mr-3 pt-2">
              <i class="material-icons">lock_outline</i>
            </div>
            <input type="password" class="form-control" placeholder="Password" aria-label="password" formControlName="password" required>
          </div>
        </div>
        <button type="submit" class="btn btn-primary mt-3">Login</button>
      </form>
    </div>
  </div>
</div>
