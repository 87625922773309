<ng-select [items]="filterValues$ | async"
  [addTag]="false"
  [multiple]="filter.multi"
  bindLabel="value"
  [typeahead]="filterInput$"
  [loading]="searchLoading"
  [(ngModel)]="filterValue"
  placeholder="{{filter.label}}"
  class="group-filter-search-box"
  *ngIf="searchSelect">
</ng-select>
<ng-select [items]="filterValues$ | async"
  [multiple]="filter.multi"
  bindLabel="value"
  [(ngModel)]="filterValue"
  [closeOnSelect]="false"
  placeholder="{{filter.label}}"
  class="group-filter-search-box"
  *ngIf="rangeSelect">
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.value}}
  </ng-template>
</ng-select>
<div class="distance-container" *ngIf="distanceSelect">
  <ng-select [items]="filterValues$ | async"
    [addTag]="false"
    [multiple]="false"
    bindLabel="value"
    [typeahead]="filterInput$"
    [loading]="searchLoading"
    [(ngModel)]="refFilterValue"
    placeholder="{{filter.label}}"
    (change)="onRefPointChange($event)"
    class="group-filter-search-box"
    *ngIf="!refPoint">
  </ng-select>
  <div class="ref-point-label text-muted" *ngIf="refPoint">
    {{refPoint.label}}
  </div>

  <label for="distanceSlider" class="mr-2">Max distance (km)</label>
  <mat-slider id="distanceSlider" min="10" max="1000" step="20" thumbLabel [(ngModel)]="filterDistance" (change)="onDistanceChanged()"></mat-slider>
</div>
