<h1 mat-dialog-title>
  Filter points
  <button type="button" class="close ml-3" aria-label="Close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </button>
</h1>

<mat-dialog-content class="mb-4">
  <small class="help text-muted">
    Filtering points will highlight them on the map and restrict search functions to this selection.
  </small>
  <app-group-filters #filtersComponent [data]="data"></app-group-filters>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="clearGroups()">Clear</button>
  <button mat-button cdkFocusInitial (click)="displayGroup()">Filter</button>
</mat-dialog-actions>
