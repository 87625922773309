import { Location } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import * as d3Color from 'd3-color';
import * as d3 from 'd3-selection';
import * as d3Tile from 'd3-tile';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DatasetsStore } from 'src/app/datasets/store';
import { MapStore } from 'src/app/map/store';
import { MapProperties } from 'src/app/map/models';
import { MapService } from 'src/app/map/services';
var MapTilesComponent = /** @class */ (function () {
    function MapTilesComponent(store, mapStore, location, mapService) {
        this.store = store;
        this.mapStore = mapStore;
        this.location = location;
        this.mapService = mapService;
        this.tilesUrl = environment.staticEndpoint + '/tiles/';
        this.groupTilesUrl = environment.staticEndpoint + '/grouptiles/';
        this.debouncedZoom$ = new Subject();
        this.tiling = false;
        this.groupVisible = false;
    }
    MapTilesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions = this.store.dataset$.subscribe(function (dataset) {
            _this.datasetKey = dataset ? dataset.key : null;
        });
        this.subscriptions.add(this.store.layers$.subscribe(function (_) { return _this.setupLayers(); }));
        this.subscriptions.add(this.debouncedZoom$.pipe(debounceTime(300), distinctUntilChanged()).subscribe(function (_) { return _this.getNewTiles(); }));
        this.subscriptions.add(this.mapStore.groups$.subscribe(function (groups) {
            _this.groupVisible = groups.length > 0;
            _this.drawGroupLayers();
        }));
    };
    MapTilesComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    MapTilesComponent.prototype.initSvg = function () {
        this.tile = d3Tile.tile().size([this.props.width, this.props.height]).wrap(false);
        this.svg = d3.select('svg.tiles-svg')
            .attr('preserveAspectRatio', 'none');
        this.setupLayers();
    };
    MapTilesComponent.prototype.setupLayers = function () {
        this.createFeColorFilters();
        this.drawLayers();
        this.drawGroupLayers();
    };
    MapTilesComponent.prototype.drawLayers = function () {
        if (this.svg) {
            this.rasterLayers = this.svg.select('g.tile-layers')
                .selectAll('g.tile-layer')
                .data(this.store.layers.filter(function (d) { return !d.inactive; }), function (d) { return d.id.toString(); })
                .join(function (enter) { return enter.append('g').attr('class', function (d) { return d.key + '-layer'; })
                .classed('tile-layer blend-multiply', true); }, function (update) { return update; }, function (exit) { return exit.remove(); });
            this.styleTileLayers();
        }
        this.tileLayers();
    };
    MapTilesComponent.prototype.styleTileLayers = function () {
        var _this = this;
        this.svg.select('g.tile-layers')
            .style('opacity', function (_) { return _this.groupVisible ? 0.2 : 1; })
            .selectAll('g.tile-layer')
            .attr('filter', function (d) { return _this.groupVisible ? '' : 'url(' + _this.location.path() + '#' + d.key + 'filter)'; });
    };
    MapTilesComponent.prototype.drawGroupLayers = function () {
        var _this = this;
        if (this.svg) {
            this.groupLayers = this.svg.select('g.group-layers')
                .selectAll('g.group-layer')
                .data(this.mapStore.groups, function (d) { return d.uuid; })
                .join(function (enter) { return enter.append('g').attr('id', function (d) { return 'g' + d.uuid; }).classed('group-layer', true); });
            this.groupTileLayers = this.groupLayers
                .selectAll('g.group-tile-layer')
                .data(this.store.layers.filter(function (d) { return !d.inactive; }), function (d) { return d.id.toString(); })
                .join(function (enter) { return enter.append('g').attr('class', function (d) { return d.key + '-layer'; })
                .classed('group-tile-layer blend-multiply', true)
                .attr('filter', function (d) { return 'url(' + _this.location.path() + '#' + d.key + 'filter-saturate)'; }); }, function (update) { return update; }, function (exit) { return exit.remove(); });
            this.styleTileLayers();
        }
        this.tileGroupLayers();
        this.getNewTiles();
    };
    MapTilesComponent.prototype.zoomed = function (transform) {
        var t = transform.translate(this.props.width / 2, this.props.height / 2).scale(512);
        this.tiles = this.tile.scale(t.k).translate([t.x, t.y])();
        this.tileLayers();
        this.tileGroupLayers();
        this.debouncedZoom$.next(transform);
    };
    MapTilesComponent.prototype.tileLayers = function () {
        var _this = this;
        if (this.rasterLayers && this.tiles) {
            this.rasterLayers.attr('transform', stringify(this.tiles.scale, this.tiles.translate));
            this.rasterLayers.each(function (raster, i, groups) {
                var images = d3.select(groups[i]).selectAll('image')
                    .data(_this.tiles, function (d) { return [d.tx, d.ty, d.z].join(); });
                images.join('image')
                    .attr('xlink:href', function (d) { return _this.buildTileUrl(raster.key, d.z, d.x, d.y); })
                    .attr('x', function (d) { return d.tx; })
                    .attr('y', function (d) { return d.ty; })
                    .attr('width', 256)
                    .attr('height', 256);
            });
        }
    };
    MapTilesComponent.prototype.tileGroupLayers = function () {
        var _this = this;
        if (this.groupLayers && this.tiles) {
            this.groupLayers.attr('transform', stringify(this.tiles.scale, this.tiles.translate));
            this.groupTileLayers.each(function (_, i, groups) {
                var images = d3.select(groups[i]).selectAll('image').data(_this.tiles, function (d) { return [d.tx, d.ty, d.z].join(); });
                images.join('image')
                    .attr('x', function (d) { return d.tx; })
                    .attr('y', function (d) { return d.ty; })
                    .attr('width', 256)
                    .attr('height', 256)
                    .on('error', function () { return d3.select(d3.event.currentTarget).attr('xlink:href', 'assets/blank.png'); });
            });
        }
    };
    MapTilesComponent.prototype.getNewTiles = function () {
        var _this = this;
        if (this.groupLayers && this.tiles) {
            this.groupLayers.each(function (group) {
                _this.tiling = true;
                var z = _this.tiles[0].z, x = _this.tiles[0].x, y = _this.tiles[0].y, xend = _this.tiles[_this.tiles.length - 1].x, yend = _this.tiles[_this.tiles.length - 1].y;
                _this.mapService.requestGroupTile(group, z, x, y, xend, yend, _this.store.layers.filter(function (d) { return !d.inactive; }))
                    .pipe(tap(function () { return _this.tiling = false; }))
                    .subscribe(function (tilesMask) {
                    var _loop_1 = function (layer, mask) {
                        _this.svg.select('#g' + group.uuid).select('g.' + layer + '-layer').selectAll('image')
                            .attr('xlink:href', function (d) {
                            if (mask[d.x - x][d.y - y] === 1.0) {
                                return _this.buildGroupTileUrl(group.uuid, layer, d.z, d.x, d.y);
                            }
                            return '';
                        });
                    };
                    for (var _i = 0, _a = Object.entries(tilesMask); _i < _a.length; _i++) {
                        var _b = _a[_i], layer = _b[0], mask = _b[1];
                        _loop_1(layer, mask);
                    }
                }, function () { return _this.tiling = false; });
            });
        }
    };
    MapTilesComponent.prototype.buildTileUrl = function (layerKey, zoom, x, y) {
        return "" + this.tilesUrl + this.datasetKey + "/" + layerKey + "/" + zoom + "/" + x + "/" + y + ".png";
    };
    MapTilesComponent.prototype.buildGroupTileUrl = function (groupUuid, layer, zoom, x, y) {
        return "" + this.groupTilesUrl + groupUuid + "/" + layer + "/" + zoom + "/" + x + "/" + y + ".png";
    };
    MapTilesComponent.prototype.setDimedMode = function (mode) {
        this.svg.classed('dimed', mode);
    };
    MapTilesComponent.prototype.createFeColorFilters = function () {
        if (this.svg) {
            this.svg.select('defs').selectAll('g.layer-filter')
                .data(this.store.layers, function (d) { return d.id.toString(); })
                .join(function (enter) { return appendFeColorFilters(enter); }, function (update) { return update; }, function (exit) { return exit.remove(); });
        }
    };
    return MapTilesComponent;
}());
export { MapTilesComponent };
function appendFeColorFilters(enter) {
    var sel = enter.append('g').classed('layer-filter', true);
    styleFeColorFilter(sel.append('filter'), false);
    styleFeColorFilter(sel.append('filter'), true);
    return sel;
}
function styleFeColorFilter(filter, saturate) {
    filter.attr('id', function (d) { return d.key + 'filter' + (saturate ? '-saturate' : ''); })
        .attr('color-interpolation-filters', 'sRGB')
        .attr('x', 0).attr('y', 0).attr('height', '100%').attr('width', '100%')
        .append('feColorMatrix').attr('type', 'matrix')
        .attr('values', function (d) {
        var c = d3Color.color(d.color).rgb();
        if (!saturate) {
            c = c.brighter(1);
        }
        return "0 0 0 0 " + (c.r / 255).toFixed(2) + "\n          0 0 0 0 " + (c.g / 255).toFixed(2) + " \n          0 0 0 0 " + (c.b / 255).toFixed(2) + " \n          1 0 0 0 0";
    });
}
function stringify(scale, translate) {
    var k = scale / 256;
    var r = scale % 1 ? Number : Math.round;
    return 'translate(' + r(translate[0] * scale) + ',' + r(translate[1] * scale) + ') scale(' + k + ')';
}
