import { User } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { shareReplay, tap, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import * as jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var TOKEN_NAME = 'jwt_token';
export var EXPIRATION_NAME = 'expires_at';
export var USER_ID_NAME = 'user_id';
var AuthService = /** @class */ (function () {
    function AuthService(http) {
        this.http = http;
        this.loginUrl = environment.apiEndpoint + '/api-token-auth/';
        this.isAdminUrl = environment.apiEndpoint + '/users/isadmin/';
        var user = this.isLoggedIn() ? new User(this.getUserId()) : null;
        this.loggedUser = new BehaviorSubject(user);
    }
    AuthService.prototype.checkIsUserAdmin = function () {
        return this.http.get(this.isAdminUrl);
    };
    AuthService.prototype.login = function (email, password) {
        var _this = this;
        return this.http.post(this.loginUrl, { email: email, password: password }).pipe(map(function (res) { return _this.setSession(res); }), shareReplay(), tap(function (user) { return _this.loggedUser.next(user); }));
    };
    AuthService.prototype.setSession = function (authResult) {
        var decoded = jwt_decode(authResult.token);
        var date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        var expiresAt = moment(date);
        localStorage.setItem(TOKEN_NAME, authResult.token);
        localStorage.setItem(EXPIRATION_NAME, JSON.stringify(expiresAt.valueOf()));
        localStorage.setItem(USER_ID_NAME, decoded.user_id);
        return new User(decoded.user_id, decoded.email);
    };
    AuthService.prototype.logout = function () {
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem(EXPIRATION_NAME);
        localStorage.removeItem(USER_ID_NAME);
        this.loggedUser.next(null);
    };
    AuthService.prototype.isLoggedIn = function () {
        return moment().isBefore(this.getExpiration());
    };
    AuthService.prototype.isLoggedOut = function () {
        return !this.isLoggedIn();
    };
    AuthService.prototype.getExpiration = function () {
        var expiration = localStorage.getItem(EXPIRATION_NAME) || '0';
        var expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    };
    AuthService.prototype.getToken = function () {
        return localStorage.getItem(TOKEN_NAME);
    };
    AuthService.prototype.getUserId = function () {
        return localStorage.getItem(USER_ID_NAME);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
