<div class="about-jumbotron jumbotron">
  <div class="container">
    <div class="d-md-flex flex-items-center gutter-md-spacious">
      <div class="col-md-7 text-center text-md-left">
        <h1 class="display-4 text-white">
          <img src="/assets/cartolab.png" width="85" height="85" class="d-inline-block align-top" alt="">
          Cartolabe
        </h1>
        <p class="about-lead px-2">
          <span class="mr-2">
            Cartolabe is a data visualization platform developped by
          </span>
          <a href="https://www.inria.fr/" class="pr-2">
            <img src="/assets/inr_logo_fr_rouge.png" width="116" height="50" class="d-inline-block align-top" alt="inria">
          </a>
          <a href="https://www.lri.fr/" class="p-2">
              <img src="/assets/cnrs.svg" width="50" height="50" class="d-inline-block align-top" alt="cnrs">
          </a>
          <a href="http://www.u-psud.fr" class="p-2">
            <img src="/assets/LogoUPSUD_2014_SANS_SIGN_UPSaclay_couleur.jpg" height="50" class="d-inline-bloc align-top" alt="upsud">
          </a>
        </p>
      </div>

      <div class="mx-auto col-sm-8 col-md-5 mt-sm-5">
        <div class="card text-center contact-card">
          <div class="card-header">
            <h4>Contact us</h4>
          </div>
          <div class="card-body">
            <p class="lead text-secondary">
              We'd love to hear from you !
            </p>
            <p class="text-secondary">Whether you need some help, have some feedback, or just want to talk ...</p>
            <a class="btn btn-outline-primary email-btn" href="mailto:{{contact}}" role="button">
              Send us an email
              <i class="material-icons">mail_outline</i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container landscape text-center">
  <div class="p-5">
    <h1>Visualize the scientific landscape</h1>
    <p class="text-secondary">Cartolabe lets you explore a scientific landscape built from unsupervised machine learning algorithms.
      Search for authors, articles, research teams & labs or even scientific terms and see where they fit on the map.
      Look for the nearest entities of each kind to understand the underlying connections that shape a specific domain.</p>
  </div>
</div>

<div class="container data">
  <div class="p-5">
    <h1 class="text-center">Datasets</h1>
    <div class="container-fluid" id="datasetsAboutContainer">
      <div class="row">
        <div class="col-sm-4 datasets-list">
          <mat-action-list>
            <button [class.active]="selectedDataset === dataset" mat-list-item (click)="selectDataset(dataset)" *ngFor="let dataset of datasets$ | async"> {{dataset.label}} </button>
          </mat-action-list>
        </div>
        <div class="col-sm-8">
          <p class="text-secondary dataset-descriptions" [innerHtml]="selectedDataset?.about_description">
          </p>
          <div class="dataset-stats" *ngIf="total > 0">
            <p class="text-secondary">
              This dataset includes <span class="stat-total font-weight-bold">{{total}}</span> entities :
            </p>
            <ul class="list-unstyled stats text-secondary">
              <li class="stat-item py-1" *ngFor="let nature of selectedDataset?.natures">
                <i [style.color]="nature.color" class="material-icons stat-icon pr-1">{{nature.icon}}</i>
                {{stats[nature.key]}} {{nature.label}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container team text-center">
  <div class="p-5">
    <h1>Teamwork</h1>
    <p class="text-secondary">
      Cartolabe was built with help from these fine people.
    </p>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="card contact-card">
          <div class="card-body">
            <h5 class="card-title">Philippe Caillou</h5>
            <p class="card-subtitle text-secondary">Associate Professor in Computer Science</p>
            <p class="card-text">INRIA - TAU team</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="card contact-card">
          <div class="card-body">
            <h5 class="card-title">Jean-Daniel Fekete</h5>
            <p class="card-subtitle text-secondary">Computer Science Researcher</p>
            <p class="card-text">INRIA - AVIZ Scientific Leader</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="card contact-card">
          <div class="card-body">
            <h5 class="card-title">Anne-Catherine Letournel</h5>
            <p class="card-subtitle text-secondary">Dev Team Leader</p>
            <p class="card-text">CNRS - LRI</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="card contact-card">
          <div class="card-body">
            <h5 class="card-title">Felix Louistisserand</h5>
            <p class="card-subtitle text-secondary">Research Developper</p>
            <p class="card-text">INRIA - TAU team</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="card contact-card">
          <div class="card-body">
            <h5 class="card-title">Jonas Renault</h5>
            <p class="card-subtitle text-secondary">Research Engineer</p>
            <p class="card-text">CNRS - LRI</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="card contact-card">
          <div class="card-body">
            <h5 class="card-title">Michele Sebag</h5>
            <p class="card-subtitle text-secondary">Principal Senior Research Fellow</p>
            <p class="card-text">INRIA - TAU Team Leader</p>
          </div>
        </div>
      </div>

    </div>



  </div>
</div>
