import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Dataset, Nature } from 'src/app/datasets/models';
import { Point } from 'src/app/map/models';
import { MapControlComponent } from '../map-control/map-control.component';
import { DatasetsStore } from 'src/app/datasets/store';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  labels$: Observable<Array<Nature>>;
  layers$: Observable<Array<Nature>>;
  dataset$: Observable<Dataset>;
  showDashboardMenu = false;

  @ViewChild('mapComponent', { static: false }) public mapComponent: MapControlComponent;

  constructor(private store: DatasetsStore) {
    this.dataset$ = this.store.dataset$;
    this.labels$ = this.store.labels$;
    this.layers$ = this.store.layers$;
  }

  ngOnInit() {
  }

  onLayerSelect(layer: Nature): void {
    this.store.toggleLayer(layer.key);
  }

  targetPoint(point: Point) {
    this.mapComponent.targetPoint(point);
  }

}
