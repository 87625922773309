<div class="card point-card" [class.active]="active" [class.open]="open" [style.border-color]="active ? nature.color : ''">
  <span class="point-clear" *ngIf="active" (click)="clear()">×</span>
  <div class="card-body">
    <div class="title">
      <div class="d-flex flex-row align-items-center">
        <span class="card-title-icon pr-2" [style.color]="nature.color">
          <i class="material-icons" title="{{nature.label}}">{{nature.icon}}</i>
        </span>
        <span class="point-label">
          <img [src]="point.img_data" *ngIf="point.img_data"/>
          {{point.label}}
          <a class="url-link" target="_blank" [attr.href]="url" *ngIf="!active && url !== ''"><i class="ml-2 material-icons" title="link">public</i></a>
        </span>
      </div>
    </div>
    <div class="card-subtitle mt-2">
      <div class="d-flex flex-row align-items-center" [style.color]="nature.color">
        <span class="point-nature">
          {{pointNature}}
          <a class="url-link" target="_blank" [attr.href]="url" *ngIf="url !== ''"><i class="ml-2 material-icons" title="link">public</i></a>
        </span>
        <span class="point-score float-right ml-auto" matTooltip="The score represents the importance of this entity">{{point.score}}</span>
      </div>
      <div class="d-flex flex-row flex-wrap justify-content-between extra-fields mt-2" *ngIf="nature.extra_fields?.length > 0">
        <div class="extra-field text-muted" *ngFor="let field of nature.extra_fields">
          <span class="extra-field-label mr-2">
            {{field.label}}
          </span>
          <span class="extra-field-value">{{point[field.key]}}</span>
        </div>
      </div>
      <!-- <div class="d-flex flex-row extra-field text-muted" *ngFor="let field of nature.extra_fields">
        <span class="extra-field-label mr-2">
          {{field.label}}
        </span>
        <span class="extra-field-value" matTooltip="{{field.tooltip}}">{{point[field.key]}}</span>
      </div> -->
    </div>
  </div>
  <div class="card-footer card-actions">
    <div class="d-flex flex-row justify-content-between">
      <i class="material-icons" (click)="getNeighbors(label.key)" matTooltip="Search for this point's nearest {{label.key}}" *ngFor="let label of labels$ | async" [style.color]="label.color">{{label.icon}}</i>
      <i class="material-icons map-filter" *ngIf="groupFilter" (click)="filterMap()" matTooltip="Filter the map with this point">input</i>
      <i class="material-icons" (click)="targeted()" matTooltip="Center and zoom the map on this point">filter_center_focus</i>
    </div>
  </div>
</div>
