import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription, SubscriptionLike, merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MapStore } from 'src/app/map/store';
import { DatasetsStore } from 'src/app/datasets/store';
import { MapState } from './map.state';

const mapStateKey = 'mapState';

@Injectable({
  providedIn: 'root'
})
export class MapHistory implements OnDestroy {

  public present = new MapState();
  private subscribed = true;
  private storeSubscription: Subscription;
  private historySubscription: SubscriptionLike;

  constructor(private mapStore: MapStore, private location: Location, private dsStore: DatasetsStore) {
    this.storeSubscription = merge(
      this.mapStore.selectedPoint$,
      this.mapStore.searchPoints$,
      this.mapStore.groups$
    ).pipe(
      filter(_ => this.dsStore.dataset && this.subscribed),
    ).subscribe(_ => this.saveState());

    this.historySubscription = this.location.subscribe((e: PopStateEvent) => {
      if (e.state && mapStateKey in e.state) {
        // console.groupCollapsed('[MapHistory] Load MapState from history');
        // console.log(e.state[mapStateKey]);
        // console.groupEnd();
        this.present = e.state[mapStateKey];
        this.setStoreState();
      }
    });
  }

  resetHistory() {
    this.present = new MapState();
  }

  saveState() {
    const state = new MapState(this.dsStore.dataset.key, this.mapStore.selectedPoint,
      this.mapStore.searchPoints, this.mapStore.searchTitle, this.mapStore.groups, this.mapStore.filters);
    if (!MapState.equal(this.present, state) && !state.isEmpty()) {
      this.present = state;
      // console.groupCollapsed('[MapHistory] Save MapState to history');
      // console.log(this.present);
      // console.groupEnd();
      this.location.go(this.location.path(), '', { [mapStateKey]: this.present });
    }
  }

  setStoreState() {
    this.subscribed = false;
    this.mapStore.setSelectedPoint(this.present.selectedPoint);
    this.mapStore.setSearchPoints(this.present.searchPoints, this.present.searchTitle);
    this.mapStore.setGroup(this.present.groups, this.present.filters);
    this.subscribed = true;
  }

  disable() {
    this.subscribed = false;
  }

  enable() {
    this.subscribed = true;
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
    this.historySubscription.unsubscribe();
  }
}
