<div class="d-md-flex flex-md-row dashboard-container">
  <aside class="menu-container p-0">
    <app-search-box></app-search-box>

    <div class="results-container mt-2">
      <app-point-list (target)="targetPoint($event)"></app-point-list>
    </div>
  </aside>

  <main role="main" class="pl-md-4 main-container d-flex flex-column">
    <div class="map-title mt-2" *ngIf="(dataset$ | async) as dataset">
      <a class="text-secondary" [routerLink]="['/about']" [fragment]="dataset.key">
        {{dataset.description}}
      </a>
    </div>
    <!-- Map -->
    <div class="map-control mt-2">
      <app-map-control #mapComponent></app-map-control>
    </div>
    <!-- End Map -->

    <!-- Map Legend -->
    <div class="map-keys mt-2 d-flex flex-column flex-md-row">
      <div class="menu-group mr-auto">
        <ul class="list-inline">
          <li class="list-inline-item list-header font-weight-bold mr-2">
            Density maps
          </li>
          <li class="list-inline-item layer-item" *ngFor="let layer of layers$ | async" (click)="onLayerSelect(layer)" [class.inactive]="layer.inactive" matTooltip="{{layer.layer_tooltip}}">
            <mat-icon [style.color]="layer.color" class="label-icon">{{layer.icon}}</mat-icon>
            {{layer.label}}
          </li>
        </ul>
      </div>
      <div class="menu-group">
        <ul class="list-inline">
          <li class="list-inline-item list-header font-weight-bold mr-2">
            Labels
          </li>
          <li class="list-inline-item label-item text-secondary" *ngFor="let label of labels$ | async" matTooltip="{{label.tooltip}}">
            <mat-icon [style.color]="label.color" class="label-icon">{{label.icon}}</mat-icon>
            {{label.label}}
          </li>
          <li class="list-inline-item cluster-item text-secondary" matTooltip="Cluster areas are displayed when you hover over gold labels">
            <mat-icon style="color: gold" class="label-icon">format_shapes</mat-icon>
            Clusters
          </li>
        </ul>
      </div>
    </div>
    <!-- End Map Legend -->
  </main>
</div>
