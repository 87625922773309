import { Component, ViewContainerRef } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  /*
  * The ViewContainerRef is required for absolute positioning of
  * the ngx-color-picker in DashboardComponent.
  */
  constructor(public vcRef: ViewContainerRef) {
    if (environment.appendGA) {
      this.appendGaTrackingCode();
    }
  }

  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-130177532-2';
      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-130177532-2');
      `;
      document.head.appendChild(script);
      document.head.appendChild(script2);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

}
