import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DatasetsStore } from 'src/app/datasets/store';
import { MapStore } from 'src/app/map/store';
import { MapService } from 'src/app/map/services';
import { MapHistory } from 'src/app/map/history';


@Injectable({
  providedIn: 'root'
})
export class MapEffects implements OnDestroy {

  constructor(private datasetsStore: DatasetsStore, private router: Router, private mapStore: MapStore,
    private mapService: MapService, private mapHistory: MapHistory) {
  }

  /**
   * This observable listens for route change events and updates the dataset
   * on the datasetsStore with the route's <key> param.
   */
  datasetGuard$ = this.router.events.pipe(
    // Don't filter only on 'map' or 'overlay' being in the url because we also
    // need to set the dataset when url is '/' and urlAfterRedirects is '/map'.
    // It's better to just always setDataset with key being either the urlParam
    // or null.
    // filter(e => e instanceof NavigationEnd && (e.url.startsWith('/map/') || e.url.startsWith('/overlay/'))),
    filter(e => e instanceof NavigationEnd),
  ).subscribe(_ => {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    this.datasetsStore.setDataset(route.snapshot.paramMap.get('key'));
  });

  /**
   * This method listens for dataset changes on the datasetsStore and updates the
   * MapStore, MapService and MapHistory. This is a bit tricky though because
   * we need to check if the dataset change is due to the user navigating
   * to a new dataset, or due to the user navigating back to a previous state.
   * In the first case, we need to reset everything. In the second case, we need
   * to partially reset some stuff, and set the rest from the history.
   */
  datasetUpdate$ = this.datasetsStore.dataset$.subscribe(dataset => {
    // console.groupCollapsed('[MapEffects] Setting new dataset');
    // console.log(dataset);
    // console.groupEnd();
    // Because popstate events happen before NavigationEnd events, the history's
    // state has already been reset at this point when we're navigating back
    const fromHistory = dataset && this.mapHistory.present.datasetKey === dataset.key;
    this.mapHistory.disable();
    this.mapStore.setDataset(dataset, !fromHistory);
    if (!fromHistory) {
      this.mapHistory.resetHistory();
    }
    this.mapService.resetService();
    this.mapHistory.enable();
  });

  ngOnDestroy() {
    this.datasetGuard$.unsubscribe();
    this.datasetUpdate$.unsubscribe();
  }

}
