/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 12, "footer", [["class", "mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "d-flex flex-row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "p-2 font-weight-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Developped by "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "p-2"], ["href", "https://www.inria.fr/"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "inria"], ["class", "d-inline-block align-top"], ["height", "50"], ["src", "/assets/inria.png"], ["width", "116"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "p-2"], ["href", "https://www.lri.fr/"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "cnrs"], ["class", "d-inline-block align-top"], ["height", "50"], ["src", "/assets/cnrs.svg"], ["width", "50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "p-2"], ["href", "http://www.u-psud.fr"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "upsud"], ["class", "d-inline-bloc align-top"], ["height", "50"], ["src", "/assets/LogoUPSUD_2014_SANS_SIGN_UPSaclay_couleur.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "copyright p-2 ml-auto font-weight-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " \u00A0\u00A9 ", " Cartolabe "])), i1.ɵppd(13, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; var currVal_1 = i1.ɵunv(_v, 12, 1, _ck(_v, 13, 0, i1.ɵnov(_v, 0), _co.now, "yyyy")); _ck(_v, 12, 0, currVal_0, currVal_1); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i3.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
