/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../map-control/map-control.component.ngfactory";
import * as i3 from "../map-control/map-control.component";
import * as i4 from "@angular/router";
import * as i5 from "../../datasets/store/datasets.store";
import * as i6 from "./overlay.component";
var styles_OverlayComponent = [i0.styles];
var RenderType_OverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverlayComponent, data: {} });
export { RenderType_OverlayComponent as RenderType_OverlayComponent };
export function View_OverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-map-control", [["class", "map-control"]], null, null, null, i2.View_MapControlComponent_0, i2.RenderType_MapControlComponent)), i1.ɵdid(2, 4440064, null, 0, i3.MapControlComponent, [i4.Router, i4.ActivatedRoute, i5.DatasetsStore], { overlay: [0, "overlay"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-overlay", [], null, null, null, View_OverlayComponent_0, RenderType_OverlayComponent)), i1.ɵdid(1, 114688, null, 0, i6.OverlayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverlayComponentNgFactory = i1.ɵccf("app-overlay", i6.OverlayComponent, View_OverlayComponent_Host_0, {}, {}, []);
export { OverlayComponentNgFactory as OverlayComponentNgFactory };
