export class User {
    id: string;
    email: string;
    password: string;
    first_name: string;
    last_name: string;

    constructor(id?: string, email?: string) {
      this.id = id;
      this.email = email;
    }
}
