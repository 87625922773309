import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Dataset, Nature } from 'src/app/datasets/models';


const predictionNature: Nature = {
  id: 99999, label: 'Prediction', key: 'prediction', checked: false, limit: 0, color: '#455a64', icon: 'emoji_objects',
  tooltip: '', layer: false, inactive: false, layer_tooltip: '', extra_fields: []
};

@Injectable({
  providedIn: 'root'
})
export class DatasetsStore {

  private _dataset$ = new BehaviorSubject<Dataset>(null);
  private _datasets$ = new BehaviorSubject<Dataset[]>([]);
  private _loaded$ = new BehaviorSubject<boolean>(false);
  private _natures$ = new BehaviorSubject<Nature[]>([]);

  public dataset$ = this._dataset$.asObservable();
  public datasets$ = this._datasets$.asObservable();
  public loaded$ = this._loaded$.asObservable();
  private _natures = this._natures$.asObservable();

  setDatasets(datasets: Dataset[]) {
    this._datasets$.next(datasets);
    this._loaded$.next(true);
  }

  setDataset(key: string) {
    const dataset = this._datasets$.getValue().find((d: Dataset) => d.key === key);
    this._dataset$.next(dataset);
    this._natures$.next(dataset && dataset.natures ? dataset.natures : []);
  }

  get dataset() {
    return this._dataset$.getValue();
  }

  get natures$(): Observable<Nature[]> {
    return this._natures.pipe(
      map(natures => [predictionNature].concat(natures))
    );
  }

  get natures(): Nature[] {
    return [predictionNature].concat(this._dataset$.getValue().natures);
  }

  get labels$(): Observable<Nature[]> {
    return this._natures.pipe(
      map(natures => natures.filter(p => p.checked)),
      distinctUntilChanged()
    );
  }

  get labels(): Nature[] {
    return this._dataset$.getValue().natures.filter(p => p.checked);
  }

  get layers$(): Observable<Nature[]> {
    return this._natures.pipe(
      map(natures => natures.filter(p => p.layer)),
      distinctUntilChanged()
    );
  }

  get layers(): Nature[] {
    return this._dataset$.getValue().natures.filter(p => p.layer);
  }

  toggleLayer(key: string) {
    const layer = this._dataset$.getValue().natures.find(n => n.key === key);
    layer.inactive = !layer.inactive;
    this._natures$.next(this._dataset$.getValue().natures);
  }

}
