<div class="search-container">
  <ng-select
    #selectComponent
    [(ngModel)]="searchBoxPoint"
    [items]="mapService.typeaheadPoints$ | async"
    [addTag]="false"
    addTagText="Add tag"
    [markFirst]="false"
    [loading]="mapService.searchLoading"
    [typeahead]="mapService.typeaheadInput$"
    placeholder="Search"
    (change)="searchBoxOnChange($event)"
    class="search-box-select">
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <span>
        <span class="ng-tag-label" *ngIf="item.nature === 'prediction'">
          Predict
        </span>
        {{item.label}}
      </span>
    </ng-template>
    <ng-template ng-footer-tmp *ngIf="mapService.typeaheadResults?.length > 0">
      <div (click)="searchBoxSelectAll()" class="text-primary">
        Show all results
      </div>
    </ng-template>
  </ng-select>

  <div class="search-filters">
    <div class="header d-flex flex-row justify-content-start align-items-center" (click)="toggleSearchFilters()">
      <i class="material-icons header" *ngIf="!showSearchFilters">keyboard_arrow_right</i>
      <i class="material-icons header" *ngIf="showSearchFilters">keyboard_arrow_down</i>
      <span class="header"> Search Filters </span>
      <mat-icon class="ml-auto" matTooltip="Use the search box or click on the map to search for points of interest">info</mat-icon>
    </div>
    <div class="filters d-flex flex-row justify-content-end" [style.height.em]="filtersHeight">
      <div class="form-check layers-check mx-1" *ngFor="let filter of mapStore.searchFilters$ | async">
        <input class="form-check-input" type="checkbox" [(ngModel)]="filter.checked" attr.id="searchCheck{{filter.key}}">
        <label class="form-check-label" attr.for="searchCheck{{filter.key}}">
          {{filter.label}}
        </label>
      </div>
    </div>
  </div>
</div>
