export class MapProperties {
  width: number;
  height: number;

  constructor(width?: number, height?: number) {
    this.width = width;
    this.height = height;
  }
}

export class MapViewboxBounds {
  xrange: number[];
  yrange: number[];
  zoom: number;

  constructor(xrange: number[], yrange: number[]) {
    this.xrange = xrange;
    this.yrange = yrange;
  }
}
