<div class="point-list-container d-flex flex-column">
  <div class="mb-4 selected-point" *ngIf="selectedPoint">
    <app-point-card
    [point]="selectedPoint"
    [open]="true"
    (action)="onPointCardAction(selectedPoint, $event)"
    [active]="true"></app-point-card>
  </div>

  <div>
    <div class="search-title font-weight-light d-flex flex-row" *ngIf="(searchTitle$ | async) as searchTitle">
      <span class="mr-1 search-title-text">{{searchTitle}}</span>
      <span class="search-title-count">{{searchCount$ | async}}</span>
      <mat-icon class="ml-auto search-clear" (click)="clearSearchResults()">close</mat-icon>
      <mat-icon matTooltip="Search results are shown on the map with a dot highlighted in cyan. Use the brightness control button on the map to highlight only search results.">info</mat-icon>
    </div>
  </div>

  <div class="search-results-container">
    <div *ngFor="let point of searchPoints$ | async" class="mt-1 point-result" (click)="selectPoint(point)">
      <app-point-card
      [point]="point"
      [open]="false"
      (action)="onPointCardAction(point, $event)"
       *ngIf="selectedPoint?.id !== point.id"></app-point-card>
    </div>
  </div>
</div>
