import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { MapService } from 'src/app/map/services';
import { FilterValue } from 'src/app/datasets/models';
import { GroupFiltersDialogComponent } from './group-filters/group-filters.component';
import { MapStore } from 'src/app/map/store';

@Component({
  selector: 'app-group-control',
  templateUrl: './group-control.component.html',
  styleUrls: ['./group-control.component.scss']
})
export class GroupControlComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription;
  breadcrumbs = 'Filter Map';
  grouped = false;
  constructor(private mapService: MapService, private mapStore: MapStore, public dialog: MatDialog) { }

  ngOnInit() {
    this.subscriptions = this.mapStore.filters$.subscribe((filters: Array<FilterValue | FilterValue[]>) => {
      if (filters && filters.length > 0) {
        this.breadcrumbs = filters.map(filter => {
          if (Array.isArray(filter)) {
            return filter.map(f => f.value).join(' ');
          } else {
            return filter.value;
          }
        }).join(' ');
        this.grouped = true;
      } else {
        this.grouped = false;
        this.breadcrumbs = 'Filter Map';
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  clearGroups(event: MouseEvent) {
    this.mapService.clearGroup();
    event.stopPropagation();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(GroupFiltersDialogComponent, { panelClass: 'group-filters-dialog', minWidth: 400, maxWidth: 500 });

    dialogRef.afterClosed().subscribe(filters => {
      if (filters && filters.length > 0) {
        this.mapService.displayGroup(filters);
      } else if (filters) {
        this.mapService.clearGroup();
      }
    });
  }

}
