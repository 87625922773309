import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as d3Scale from 'd3-scale';
import * as d3 from 'd3-selection';
import * as d3Zoom from 'd3-zoom';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Dataset } from 'src/app/datasets/models';
import { DatasetsStore } from 'src/app/datasets/store';
import { Point, MapProperties } from 'src/app/map/models';
import { MapLabelsComponent } from '../map-labels/map-labels.component';
import { MapTilesComponent } from '../map-tiles/map-tiles.component';


@Component({
  selector: 'app-map-control',
  templateUrl: './map-control.component.html',
  styleUrls: ['./map-control.component.scss']
})
export class MapControlComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('tilesComponent', {static: false}) public tilesComponent: MapTilesComponent;
  @ViewChild('labelsComponent', {static: false}) public labelsComponent: MapLabelsComponent;
  @Input() overlay: boolean;
  props = new MapProperties();
  zoomContainer: any;
  private zoom: any;
  maxZoom = 32;
  private zoomTransform: d3Zoom.ZoomTransform;
  zoomBar = 0;
  zoomBarScale: d3Scale.ScaleLinear<number, number>;

  private subscriptions: Subscription;
  private datasetKey: string;
  initZoom: InitZoom;
  private dimed = false;
  showGroupsCtrl = false;

  constructor(private router: Router, private route: ActivatedRoute, private store: DatasetsStore) {
  }

  ngOnInit() {
    this.subscriptions = this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('k') && params.has('x') && params.has('y')) {
        this.initZoom = {
          x: parseFloat(params.get('x')),
          y: parseFloat(params.get('y')),
          k: parseFloat(params.get('k')),
          used: false
        };
      }
    });
  }

  ngAfterViewInit() {
    this.zoomContainer = d3.select('.zoom-container');
    const bbox = this.zoomContainer.node().getBoundingClientRect();
    this.props.width = bbox.width - 4;
    this.props.height = bbox.height - 4;
    this.tilesComponent.initSvg();
    this.labelsComponent.initSvg();
    this.subscriptions.add(this.store.dataset$.pipe(delay(1)).subscribe((dataset: Dataset) => {
      this.resetZoom(dataset);
      this.datasetKey = dataset ? dataset.key : null;
      this.showGroupsCtrl = (dataset && dataset.filters && dataset.filters.length > 0);
    }));
    d3.select('#settingsBrightnessBtn')
      .on('mouseover', () => this.setDimedMode(true))
      .on('mouseout', () => this.setDimedMode(false))
      .on('click', () => {
        this.dimed = !this.dimed;
        this.setDimedMode(this.dimed);
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  targetPoint(selectedPoint: Point) {
    const projection = this.labelsComponent.getProjection(selectedPoint.position[0], selectedPoint.position[1]);
    this.zoomContainer.transition().duration(2000).call(this.zoom.transform,
      d3Zoom.zoomIdentity
        .translate(this.props.width / 2, this.props.height / 2)
        .scale(this.maxZoom)
        .translate(-projection[0], -projection[1]));
  }

  zoomed() {
    this.zoomTransform = d3.event.transform;
    this.tilesComponent.zoomed(this.zoomTransform);
    this.labelsComponent.zoomed(this.zoomTransform);
    this.zoomBar = this.zoomBarScale(this.zoomTransform.k);
  }

  resetZoom(dataset: Dataset) {
    if (dataset && dataset.key) {
      let m = dataset.max_zoom ? dataset.max_zoom : 7;
      if (m > 2) {
        m -= 2;
      }
      this.maxZoom = Math.pow(2, m);
      this.zoom = d3Zoom.zoom().scaleExtent([1, this.maxZoom]).on('zoom', this.zoomed.bind(this));
      this.zoomBarScale = d3Scale.scaleLinear().domain([1, this.maxZoom]).rangeRound([0, 3]);
      // Apply a zoom transform equivalent to projection.{scale,translate,center}.
      let initZ = d3Zoom.zoomIdentity;
      if (this.initZoom && !this.initZoom.used) {
        const projection = this.labelsComponent.getProjection(
          this.initZoom.x, this.initZoom.y);
        initZ = initZ.translate(this.props.width / 2, this.props.height / 2)
          .scale(this.initZoom.k)
          .translate(-projection[0], -projection[1]);
        this.initZoom.used = true;
      }
      this.zoomContainer.call(this.zoom).call(this.zoom.transform, initZ);
    }
  }

  toggleFullScreen() {
    if (!this.datasetKey) {
      return;
    }
    const c = this.labelsComponent.getCenterCoordinates();
    const route = this.overlay ? '/map' : '/overlay';
    this.router.navigate([route, this.datasetKey, { x: c[0], y: c[1], k: this.zoomTransform.k }]);
  }

  /**
  * Zoom using the button. Use direction = 1 to zoom in, -1 to zoom out.
  */
  doZoom(direction: number) {
    const scaleFactor = direction === 1 ? 2 : 0.5;
    this.zoomContainer.transition().duration(750).call(this.zoom.scaleBy, scaleFactor);
  }

  /**
  * Fit the zoom to a box defined by bounds.
  */
  fitZoom(bounds: number[][]) {
    const dx = bounds[1][0] - bounds[0][0],
      dy = bounds[1][1] - bounds[0][1],
      x = (bounds[0][0] + bounds[1][0]) / 2,
      y = (bounds[0][1] + bounds[1][1]) / 2,
      scale = Math.max(1, Math.min(this.maxZoom, 0.9 / Math.max(dx / this.props.width, dy / this.props.height)));

    const transform = d3Zoom.zoomIdentity
      .translate(this.props.width / 2 - scale * x, this.props.height / 2 - scale * y)
      .scale(scale);
    this.zoomContainer.transition().duration(750).call(this.zoom.transform, transform);
  }

  setDimedMode(mode: boolean) {
    if (this.dimed) {
      return;
    }
    this.labelsComponent.setDimedMode(mode);
    this.tilesComponent.setDimedMode(mode);
  }

}

interface InitZoom {
  x: number;
  y: number;
  k: number;
  used: boolean;
}
