import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as Collections from 'typescript-collections';
import { Dataset, SearchFilter, Group, FilterValue } from 'src/app/datasets/models';
import { Point } from 'src/app/map/models';


@Injectable({
  providedIn: 'root'
})
export class MapStore {

  private _viewBoxPoints$ = new BehaviorSubject<Point[]>([]);
  private _selectedPoint$ = new BehaviorSubject<Point>(null);
  private _searchPoints$ = new BehaviorSubject<Point[]>([]);
  private _groups$ = new BehaviorSubject<Group[]>([]);
  private _filters$ = new BehaviorSubject<Array<FilterValue | FilterValue[]>>([]);
  private _searchTitle$ = new BehaviorSubject<string>('');
  private _searchFilters$ = new BehaviorSubject<SearchFilter[]>([]);
  private _clusterPoints = new Array<Point>();
  private _labelPoints = new Array<Point>();

  public viewBoxPoints$ = this._viewBoxPoints$.asObservable();
  public selectedPoint$ = this._selectedPoint$.asObservable();
  public searchPoints$ = this._searchPoints$.asObservable();
  public groups$ = this._groups$.asObservable();
  public filters$ = this._filters$.asObservable();
  public searchTitle$ = this._searchTitle$.asObservable();
  public searchFilters$ = this._searchFilters$.asObservable();

  resetViewBoxPoints() {
    this._clusterPoints = [];
    this._labelPoints = [];
  }

  addViewBoxPoints(points: Point[]) {
    this._labelPoints = this._labelPoints.concat(points);
    this._viewBoxPoints$.next(points);
  }

  setClusters(points: Point[]) {
    this._clusterPoints = points;
    this._labelPoints = this._labelPoints.concat(points);
    this._viewBoxPoints$.next(points);
  }

  clearClusters() {
    this._clusterPoints = [];
  }

  setDataset(dataset: Dataset, resetState: boolean = true) {
    this._clusterPoints = [];
    this._labelPoints = [];
    if (resetState) {
      this._filters$.next([]);
      this._searchTitle$.next('');
      this._groups$.next([]);
      this._selectedPoint$.next(null);
      this._searchPoints$.next([]);
    }
    if (dataset && dataset.natures) {
      this._searchFilters$.next(dataset.natures.filter(p => p.checked)
        .map(p => new SearchFilter(p.label, p.key, p.limit > 0)));
    } else {
      this._searchFilters$.next([]);
    }
  }

  setSelectedPoint(point: Point, redraw: boolean = false) {
    const previous = this._selectedPoint$.getValue();
    if (previous && previous.is_selected) {
      previous.is_selected = false;
    }
    if (point) {
      point.is_selected = true;
    }
    this._selectedPoint$.next(point);
    if (redraw) {
      this._viewBoxPoints$.next([point]);
    }
  }

  get selectedPoint() {
    return this._selectedPoint$.getValue();
  }

  setSearchPoints(points: Point[], title: string) {
    this._searchTitle$.next(title);
    this._searchPoints$.next(points);
  }

  get searchPoints() {
    return this._searchPoints$.getValue();
  }

  get searchTitle() {
    return this._searchTitle$.getValue();
  }

  get groupQueryString() {
    const groups = this._groups$.getValue();
    if (groups.length > 0) {
      return groups[0].query;
    }
    return '';
  }

  get clusterPoints(): Point[] {
    return this._clusterPoints;
  }

  get labelPoints(): Collections.Set<Point> {
    const points = new Collections.Set<Point>(p => p.id);
    // Add the selectedPoint first
    const selectedPoint = this._selectedPoint$.getValue();
    if (selectedPoint) {
      // Force is_search_result to false for selected point so that it displays properly
      selectedPoint.is_search_result = false;
      points.add(selectedPoint);
    }
    // Add the searchPoints first to make sure they're all added to the set.
    const searchPoints = this._searchPoints$.getValue();
    if (searchPoints && searchPoints.length > 0) {
      searchPoints.forEach(p => {
        p.is_search_result = true;
        points.add(p);
      });
    }
    this._labelPoints.forEach(p => points.add(p));
    return points;
  }

  get searchFilters(): SearchFilter[] {
    return this._searchFilters$.getValue();
  }

  clearGroup() {
    this._groups$.next([]);
    this._filters$.next([]);
  }

  setGroup(groups: Group[], filters: Array<FilterValue | FilterValue[]>) {
    this._filters$.next(filters);
    this._groups$.next(groups);
  }

  get groups() {
    return this._groups$.getValue();
  }

  get filters() {
    return this._filters$.getValue();
  }

}
