import { Injectable } from '@angular/core';
import { Dataset, FilterValue } from '../models/dataset';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatasetService {

  private datasetsUrl = environment.apiEndpoint + '/datasets/';
  constructor(private http: HttpClient) { }

  getDatasets() {
    return this.http.get<Dataset[]>(this.datasetsUrl)
      .pipe(catchError(this.handleError('getting datasets')));
  }

  getDataset(key: string) {
    return this.http.get<Dataset>(`${this.datasetsUrl}${key}/`)
      .pipe(catchError(this.handleError('getting dataset ' + key)));
  }

  searchFilterValues(dataset: Dataset, filterKey: string, filterValue: string, limit: string) {
    let params = new HttpParams();
    params = params.set(filterKey, filterValue);
    params = params.set('limit', limit);
    return this.http.get<FilterValue[]>(`${this.datasetsUrl}${dataset.key}/filtervalues/`, { params: params })
      .pipe(catchError(this.handleError('getting filter values for ' + dataset.key)));
  }

  searchFilterValuesByDistance(dataset: Dataset, filterKey: string, location: string, distance: number) {
    let params = new HttpParams();
    params = params.set('filterKey', filterKey);
    params = params.set('distance', distance.toString());
    params = params.set('location', location);
    return this.http.get<FilterValue[]>(`${this.datasetsUrl}${dataset.key}/filtervalues/`, { params: params })
      .pipe(catchError(this.handleError('getting filter values for ' + dataset.key)));
  }

  getDatasetStats(key: string) {
    return this.http.get<{ [nature: string]: number }>(`${this.datasetsUrl}${key}/stats/`)
      .pipe(catchError(this.handleError('getting stats for dataset ' + key)));
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError(operation = 'operation') {
    return (error: HttpErrorResponse) => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(error.message);
        // console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
      }

      // return an observable with a user-facing error message
      return throwError(`An error occured while ${operation}. Please try again later.`);
    };
  }
}
