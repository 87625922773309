import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap} from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  private returnUrl: string;
  private subscriptions: Subscription;
  loginForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  constructor(private toastr: ToastrService, private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.subscriptions = this.route.queryParams.subscribe((params: ParamMap) => {
      const url =  params['returnUrl'];
      this.returnUrl = url ? url : '/';
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      this.toastr.error('Please fill in the required fields to login.');
      return;
    }
    this.authService.login(this.loginForm.value['email'], this.loginForm.value['password']).subscribe(
      () => { },
      () => {
        this.toastr.error('Unable to login with the information provided. Please check that you entered your credentials correctly.');
      }, () => {
        this.router.navigateByUrl(this.returnUrl);
      });
  }

}
