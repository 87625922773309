import { OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DatasetsStore } from 'src/app/datasets/store';
import { MapStore } from 'src/app/map/store';
import { MapService } from 'src/app/map/services';
import { MapHistory } from 'src/app/map/history';
import * as i0 from "@angular/core";
import * as i1 from "../../datasets/store/datasets.store";
import * as i2 from "@angular/router";
import * as i3 from "../store/map.store";
import * as i4 from "../services/map.service";
import * as i5 from "../history/map.history";
var MapEffects = /** @class */ (function () {
    function MapEffects(datasetsStore, router, mapStore, mapService, mapHistory) {
        var _this = this;
        this.datasetsStore = datasetsStore;
        this.router = router;
        this.mapStore = mapStore;
        this.mapService = mapService;
        this.mapHistory = mapHistory;
        /**
         * This observable listens for route change events and updates the dataset
         * on the datasetsStore with the route's <key> param.
         */
        this.datasetGuard$ = this.router.events.pipe(
        // Don't filter only on 'map' or 'overlay' being in the url because we also
        // need to set the dataset when url is '/' and urlAfterRedirects is '/map'.
        // It's better to just always setDataset with key being either the urlParam
        // or null.
        // filter(e => e instanceof NavigationEnd && (e.url.startsWith('/map/') || e.url.startsWith('/overlay/'))),
        filter(function (e) { return e instanceof NavigationEnd; })).subscribe(function (_) {
            var route = _this.router.routerState.root;
            while (route.firstChild) {
                route = route.firstChild;
            }
            _this.datasetsStore.setDataset(route.snapshot.paramMap.get('key'));
        });
        /**
         * This method listens for dataset changes on the datasetsStore and updates the
         * MapStore, MapService and MapHistory. This is a bit tricky though because
         * we need to check if the dataset change is due to the user navigating
         * to a new dataset, or due to the user navigating back to a previous state.
         * In the first case, we need to reset everything. In the second case, we need
         * to partially reset some stuff, and set the rest from the history.
         */
        this.datasetUpdate$ = this.datasetsStore.dataset$.subscribe(function (dataset) {
            // console.groupCollapsed('[MapEffects] Setting new dataset');
            // console.log(dataset);
            // console.groupEnd();
            // Because popstate events happen before NavigationEnd events, the history's
            // state has already been reset at this point when we're navigating back
            var fromHistory = dataset && _this.mapHistory.present.datasetKey === dataset.key;
            _this.mapHistory.disable();
            _this.mapStore.setDataset(dataset, !fromHistory);
            if (!fromHistory) {
                _this.mapHistory.resetHistory();
            }
            _this.mapService.resetService();
            _this.mapHistory.enable();
        });
    }
    MapEffects.prototype.ngOnDestroy = function () {
        this.datasetGuard$.unsubscribe();
        this.datasetUpdate$.unsubscribe();
    };
    MapEffects.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapEffects_Factory() { return new MapEffects(i0.ɵɵinject(i1.DatasetsStore), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.MapStore), i0.ɵɵinject(i4.MapService), i0.ɵɵinject(i5.MapHistory)); }, token: MapEffects, providedIn: "root" });
    return MapEffects;
}());
export { MapEffects };
