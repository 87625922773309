<svg class="labels-svg">
  <defs class="labels-filters">
    <filter id="monochromeYellow" color-interpolation-filters="sRGB" x="0" y="0" height="100%" width="100%">
      <feColorMatrix type="matrix"
        values="0 0.45 0 0 0.20
                0 0.45 0 0 0.20
                0 0.10 0 0 0.05
                0 0 0 1 0" />
    </filter>
  </defs>
  <g class="voronoi-g"></g>
  <g class="labels-g"></g>
</svg>
